.table-with-scroll-wrapper {
	display: flex;
}

.left-table {
	width: 25%;
}

.left-table th {
	padding: 7px 10px;

	text-align: left;
	font-weight: normal;
	color: #1c1c1c;

	border: 1px solid #ccc;
	border-right-width: 0;

	background-color: #f6f6f6;
}

.right-table-wrapper {
	flex-grow: 1;
	width: 75%;
	overflow: auto;
}

.right-table-wrapper * {
	box-sizing: border-box;
}