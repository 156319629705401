@font-face {
	font-family: 'Tahoma';
	src: url('/fonts/Tahoma.woff') format('woff'), url('/fonts/Tahoma.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
    font-display: swap;
}

@font-face {
	font-family: 'Peace Sans';
	src: url('/fonts/PeaceSans.woff') format('woff'), url('/fonts/Peace-Sans.ttf') format('truetype');
	font-weight: 900;
	font-style: normal;
    font-display: swap;
}

@fa-font-path: "/fonts/fontawesome/webfonts";

@font-face {
	font-family: 'Bebas Neue';
	src: url('/fonts/BebasNeueRegular.woff') format('woff'), url('/fonts/BebasNeueRegular.otf') format('opentype');
	font-weight: normal;
	font-style: normal;
    font-display: swap;
}

@font-face {
	font-family: 'Bebas Neue';
	src: url('/fonts/BebasNeueBold.woff') format('woff'), url('/fonts/BebasNeueBold.otf') format('opentype');
	font-weight: bold;
	font-style: normal;
    font-display: swap;
}
