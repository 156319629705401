.top-block {
  @media all and (max-width: 1099px) {
    display: none;
  }

  @media all and (min-width: 1100px) {
    display: block;
    width: 25%;

    background-color: @primary-color;
  }
}

.full-container {
  @media all and (min-width: 1100px) {
    display: flex;
    justify-content: space-between;
    padding: 0 !important;
  }
}

.container__content {
  @media all and (min-width: 1100px) {
    width: 73% !important;
    flex-grow: 0 !important;
  }
}

.menu-btn {
  @media all and (min-width: 1100px) {
    pointer-events: none;
  }
}

.goods-table .good-chars {
  @media all and (min-width: 1100px) {
    min-height: 75px;
  }
}

.content-block.block_elem-list {
  @media (min-width: 992px) {
    padding: 27px 0 10px;
  }
}

.line__payment {
  width: 95%;
  height: 0.5px;
  border: 0.5px solid rgba(0, 0, 0, 0.2);
  margin: 0 auto 10px;
}

.line__payment_2 {
  width: 40%;
  height: 0.5px;
  border: 0.5px solid rgba(0, 0, 0, 0.2);
  margin: 10px auto;
}

.payment-block p {
  text-align: center;
  margin-top: 13px;
}

.payment-block img {
  display: block;
  margin: 0 auto;
}

.links-4 {
  @media (max-width: 550px) {
    flex-direction: column;
  }
}