/*-------------Global styles ---------------------------------*/
html,
body {
    height: 100%;
}

body {
    position: relative;

    display: flex;
    flex-direction: column;

    /*height: ~'calc(100% + 80px)';*/
}

/*limited reset*/
html,
body,
div,
section,
article,
aside,
header,
hgroup,
footer,
nav,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
address,
time,
span,
em,
strong,
img,
ol,
ul,
li,
figure,
canvas,
video,
th,
td,
tr {
    margin: 0;
    padding: 0;
    border: 0;
    vertical-align: baseline;
}

img {
    max-width: 100%;
    vertical-align: bottom;
}

*,
*:after,
*:before {
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
}

* {
    margin: 0;
    padding: 0;
}

.clearfix:before,
.clearfix:after {
    content: "";
    display: table;
    clear: both;
}

/* For IE 6/7 (trigger hasLayout) */
.clearfix {
    zoom: 1;
}

.clear {
    clear: both;
}

body {
    font: @greate-font;
    color: @body-text-color;
    left: 0 !important;

    min-width: 320px;

    .all-content {
        position: relative;

        width: 100%;
        margin: 0 auto;
        padding-top: 55px;
        flex-grow: 1;
        display: flex;
        flex-direction: column;

        &.service-page {
            justify-content: space-between;
        }
    }

    counter-reset: section;
}

p {
    margin-bottom: 10px;
}

a {
    color: @primary-color;
    cursor: pointer;
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
}

input,
textarea,
select,
button {
    font: @greate-font;
}

.button(@btn-color: @primary-color, @color: @font-revers-color) {
    position: relative;

    display: inline-block;
    padding: 5px 15px;

    font-family: @special-font-family;
    font-size: 14px;
    text-decoration: none;
    color: @color;

    background-color: @btn-color;
    border-radius: @base-border-radius;
    border: none;
    text-shadow: 2px 2px 2px #000000;

    transition: 0.5s;

    cursor: pointer;
    outline: none;

    &:hover,
    &:focus {
        transform: scale(1.05);

        background-image: linear-gradient(0deg, darken(@btn-color, 10%), darken(@btn-color, 10%));
    }

    &:active {
        transform: scale(.95);
    }
}

.sizes(@width, @height, ) {
    width: @width;
    height: @height;
}

.btn {
    &-default {
        .button(@warning-color);
    }

    &-warning {
        .button(@warning-color);

        padding: 15px 40px;

        font-family: @title-font-family;
        font-weight: 900;

        @media all and (min-width: 768px) {
            padding: 10px 30px;

            font-size: 16px;
        }
    }

    &-active {
        .button(#001e60);
        /* @primary-color */

        &__basket {
            margin-left: 7px;

            font-size: 16px;
        }
    }

    &-info {
        .button(@info-color);
    }

    &-light {
        .button(@secondary-color, @body-text-color);

        box-shadow: 0px 1px 6px 0 lighten(@shadow-color, 20%);

        background-image: linear-gradient(to bottom, lighten(@secondary-color, 10%), darken(@secondary-color, 10%));

        &:hover,
        &:focus {
            background-image: linear-gradient(to bottom, lighten(@secondary-color, 10%), darken(@secondary-color, 10%));
        }
    }

    &-down {
        .button-circle-arrow(@bg-color: @info-color, @size: 26px);

        &::after {
            top: 7px;
            left: 7px;

            transform: rotate(45deg);
        }

        &::before {
            top: 5px;
            left: 12px;

            height: 15px;
            width: 2px;
        }
    }

    &-up {
        transform: rotate(180deg);
        transition: .3s;

        &:hover,
        &:focus {
            transform: scale(1.2) rotate(180deg);
        }
    }
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4 {
    font-weight: @base-font-weight;
    line-height: @base-line-height;

    margin-bottom: 10px;
}

h1,
.h1 {
    font-size: 24px;
}

.service-page__title h1,
.service-page__title .h1 {
    text-align: left;
}

h2,
.h2 {
    font-family: @special-font-family;
    font-size: 12.5px;

    @media all and (min-width: 450px) {
        font-size: 15px;
    }

    @media all and (min-width: 768px) {
        font-size: 20px;
    }
}

h3,
.h3 {
    font-size: 18px;
}

h4,
.h4 {
    font-size: 16px;
}

.container {
    margin: 0 auto;
    padding-left: 15px;
    padding-right: 15px;
    box-sizing: border-box;
    width: 100%;

    @media all and (max-width: 768px) {
        padding-left: 10px;
        padding-right: 10px;
    }
}

.h-m0auto {
    margin: 0 auto;
}

.h-mt-40 {
    margin-top: -40px;
}

.h-mt-20 {
    margin-top: -20px
}

.h-mt-15 {
    margin-top: -15px
}

.h-mt-5 {
    margin-top: -5px;
}

.h-mt0 {
    margin-top: 0 !important;
}

.h-mt6 {
    margin-top: 6px !important;
}

.h-mt10 {
    margin-top: 10px
}

.h-mt12 {
    margin-top: 12px;
}

.h-mt15 {
    margin-top: 15px;
}

.h-mt20 {
    margin-top: 20px !important;
}

.h-mt25 {
    margin-top: 25px;
}

.h-mt30 {
    margin-top: 30px;
}

.h-mt35 {
    margin-top: 35px;
}

.h-mt40 {
    margin-top: 40px !important;
}

.h-mt45 {
    margin-top: 45px;
}

.h-mt50 {
    margin-top: 50px;
}

.h-mt60 {
    margin-top: 60px;
    @media (max-width: 600px) {
      margin-top: 30px;
    }
}

.h-mb-10 {
    margin-bottom: -10px !important;
}

.h-mb0 {
    margin-bottom: 0px !important;
}

.h-mb5 {
    margin-bottom: 5px !important;
}

.h-mb10 {
    margin-bottom: 10px !important;
}

.h-mb12 {
    margin-bottom: 12px;
}

.h-mb15 {
    margin-bottom: 15px;
}

.h-mb20 {
    margin-bottom: 20px;
}

.h-mb25 {
    margin-bottom: 25px
}

.h-mb30 {
    margin-bottom: 30px
}

.h-mb35 {
    margin-bottom: 35px !important;
}

.h-mb40 {
    margin-bottom: 40px !important
}

.h-mb50 {
    margin-bottom: 50px !important
}

.h-mb60 {
    margin-bottom: 60px !important
}

.h-mb70 {
    margin-bottom: 70px !important
}

.h-mb80 {
    margin-bottom: 80px
}

.h-ml0 {
    margin-left: 0px !important
}

.h-ml5 {
    margin-left: 5px;
}

.h-ml10 {
    margin-left: 10px
}

.h-p20 {
    padding: 20px
}

.h-pt0 {
    padding-top: 0px !important
}

.h-pt10 {
    padding-top: 10px !important
}

.h-pt15 {
    padding-top: 15px;
}

.h-pt20 {
    padding-top: 20px;
}

.h-pt30 {
    padding-top: 30px;
}

.h-pb0 {
    padding-bottom: 0 !important;
}

.h-pb3 {
    padding-bottom: 3px !important;
}

.h-pb20 {
    padding-bottom: 20px;
}

.h-pb30 {
    padding-bottom: 30px;
}

.h-pb35 {
    padding-bottom: 35px;
}

.h-pb60 {
    padding-bottom: 60px;
}

.h-pr5 {
    padding-right: 5px;
}

.h-pr20 {
    padding-right: 20px;
}

.h-pl10 {
    padding-left: 10px;
}

.h-pl15 {
    padding-left: 15px;
}

.h-pl30 {
    padding-left: 30px;
}

.all-content__top,
.all-content__content,
.cart__container {
    width: 100%;
}

.content-block {
    &.h-pt15 {
        @media all and (max-width: 768px) {
            padding-top: 15px;
        }
    }

    &.h-pb10 {
        @media all and (max-width: 768px) {
            padding-bottom: 10px;
        }
    }

    &.h-pb15 {
        @media all and (max-width: 768px) {
            padding-bottom: 15px;
        }
    }

    &.h-pb35 {
        @media all and (max-width: 768px) {
            padding-bottom: 35px;
        }
    }
}

.f-12 {
    font-size: 12px;
}

.f-14 {
    font-size: 14px;
    line-height: 1.15;
}

.f-16 {
    font-size: 16px;
    line-height: 1.4;
}

.f-18 {
    font-size: 18px
}

.f-20 {
    font-size: 20px
}

.f-22 {
    font-size: 22px
}

.f-24 {
    font-size: 24px
}

.f-30 {
    font-size: 30px
}

.f-36 {
    font-size: 36px;
}

.f-left {
    text-align: left;
}

.f-right {
    text-align: right;
}

.f-center {
    text-align: center !important;
}

.f-justify  {
    text-align: justify !important;
}

.f-none {
    float: none !important;
}

.f-bold {
    font-weight: bold;
}

.f-uppercase {
    text-transform: uppercase;
}

.f-underline {
    text-decoration: underline;
}

.f-attention {
    color: @attention-color;
}

.f-attention-light {
    color: @attention-color-light;
}

.f-light-base {
    color: @base-color-light;
}

.f-primary-light {
    color: @primary-color-light;
}

.f-primary {
    color: @primary-color;
}

.f-red {
    color: #F63F17 !important;
}

.list-unstyled {
    list-style: none;
}

.img-l,
.img-r {
    margin: 5px auto;

    display: block;
}

.z-index(@z-index) {
    z-index: ~ 'calc(@{z-index} - 1)';
}

.border-top-1 {
    border-top: 1px solid @secondary-color;
}

.d-f {
    display: flex;
}

.d-f-wr {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  div.w-49 {
    width: 100%;

    @media (min-width: 800px) {
      width: 49%;
    }
  }
}

.flex_tree {
  display: flex;
  align-items: center;
  justify-content: space-between;

  img {
    margin: 0 10px;
  }

  @media (max-width: 600px) {
    flex-wrap: wrap;

    img {
      margin: 0;
      width: 50%;

      &:last-child {
        order: 2;
      }
    }

    ul {
      order: 3;
    }
  }

}

.fl-center {
  align-items: center;
}

header .logo {
    /*width: 140px;
    max-width: 100%;
    height: auto;*/
    margin: 0 auto;
    height: 47px;
    width: auto;
}

.top-menu {
    background-color: @base-color;
    width: 100%;

    padding-top: 7px;
    padding-bottom: 10px;
}

.top-menu .container {
    position: relative;
}

.logo-block {
    display: none;
}

#mobile-header {
    width: 100%;
    height: 54px;

    background-color: @base-revers-color;
    box-shadow: 0px 0px 5px 0 rgba(0, 0, 0, 0.9);
}

.mobile-header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 10px;
    height: 100%;
}

.h-contacts {
    .a-contacts {
        padding-top: 5px;
    }

    & > div:first-child {
        margin-top: -3px;
    }
}

.to-top_btn {
    color: @font-revers-color;

    border: none;
    background-color: transparent;
    background-image: none;

    cursor: pointer;

    outline: none;

    &::after {
        content: '';

        display: inline-block;
        vertical-align: sub;
        height: 8px;
        width: 8px;
        margin-left: 5px;

        border: 2px solid @font-revers-color;
        border-right-color: transparent;
        border-bottom-color: transparent;

        background-color: transparent;

        transform: rotate(45deg);

        cursor: pointer;
        outline: none;
    }
}

.all-content__content.h-mt30 {
    margin-top: 0;
}

.sidr {
    top: 53px;
    left: 0;
    bottom: auto;
    .z-index(1500);

    color: @font-revers-color;

    background: @base-revers-color;

    a {
        color: @font-revers-color;
    }

    @media all and (max-width: 767px) {
        max-height: ~'calc(100vh - 159px)';
        overflow-y: scroll;
    }

    @media all and (min-width: 768px) {
        width: 490px;
        height: auto;
        max-height: ~'calc(100% - 54px)';
        overflow: auto;
    }

    @media all and (min-width: 1200px) {
        left: ~ 'calc(50% - @{half-content})' !important;
    }
}

.sidr-open {

    @media all and (max-width: 767px) {
        overflow: hidden;
    }

    .sidr-overlay {
        background: rgba(0, 0, 0, .5);
        width: 100%;
        height: 100%;
        position: fixed;
        top: 0;
        left: 0;
        .z-index(10);
    }
}

.a-tel {
    color: @font-color;
    font-size: 20px;
    text-decoration: none;
}

.a-contacts {
    font-size: 14px;
}

.round-slider {
    max-width: 880px;
    margin: 0 auto;
    padding-bottom: 5px;

    border-bottom: 1px solid #000000;

    @media all and (min-width: 768px) {
        margin-bottom: 30px;
    }
}

.round-slider .bx-viewport {
    border-radius: 10px;
    box-shadow: 0px 0px 5px 0 rgba(0, 0, 0, 0.9);
}

.main-slider {
    position: relative;
    .z-index(3);

    display: flex;
    align-items: flex-end;
}

.main-slider__item {
    text-align: center;
}

.main-slider__img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    display: flex;
    justify-content: flex-end;
    align-items: flex-end;

    & img {
        opacity: .5;

        max-width: 320px;

        @media (min-width: 425px) {
            margin-right: 0 !important;
        }

        @media (min-width: 768px) {
            opacity: 1;

            margin-right: auto !important;
        }

        @media (min-width: 1250px) {
            opacity: 1;

            max-width: 100%;
        }
    }

    @media (min-width: 768px) {
        position: initial;

        justify-content: center;
        flex-shrink: 0;
        flex-grow: 1;

        min-width: 50%;
    }

    @media (min-width: 1250px) {}
}

.main-slider__desc {
    position: relative;
    .z-index(4);

    color: @font-revers-color;

    width: 100%;
    padding-top: 5px;
    padding-bottom: 40px;

    @media (min-width: 768px) {
        min-width: 50%;
    }

    @media (min-width: 1250px) {
        padding-top: 0;
    }
}

.main-slider__desc-small {
    @media (min-width: 1250px) {
        & .main-slider__tit {
            margin-bottom: 20px;
        }

        & .main-slider__t-big {
            margin-bottom: 30px;
        }
    }
}

.main-slider__tit {
    font-size: 25px;
    font-weight: lighter;
    line-height: 1;

    @media (min-width: 768px) {
        font-size: 30px;
    }

    @media (min-width: 1250px) {
        font-size: 45px;
    }
}

.main-slider__t-big {
    font-size: 30px;
    font-weight: bold;
    text-transform: uppercase;
    line-height: 1;

    @media (min-width: 768px) {
        font-size: 45px;
    }

    @media (min-width: 1250px) {
        font-size: 60px;
    }
}

.content h1,
.content .h1,
.content h2,
.content .h2 {
    text-align: center;
}

.content-block {
    padding: 30px 0 50px;

    @media all and (max-width: 768px) {
        padding: 20px 0;
    }
}

.elem-list {
    display: flex;
    flex-wrap: wrap;

    .elem-list_item {
        background-color: @base-color;

        padding: 10px;
        box-sizing: border-box;

        margin-top: 0;
        margin-bottom: 0;

        display: flex;
        flex-direction: column;
        justify-content: flex-end;

        width: 100%;
    }
}

.elem-list .btn {
    padding: 7px 10px;
}

.elem-list > .elem-list_item:last-child {
    margin-bottom: 0;
}

.elem-list_item-name {
    margin-bottom: 6px;

    font-weight: normal;
    text-align: center;
    font-size: 12px;

    @media all and (min-width: 768px) {
        margin-bottom: 5px;

        font-size: 16px;

    }
}

.goods-table .elem-list_item-name {
    font-size: 14px;
}

.elem-list_item-link {
    display: block;

    text-decoration: none;
    color: @body-text-color;

    &:first-letter {
        text-transform: uppercase;
    }
}

.goods-desc_block p.h1:first-letter,
.good__descr p:first-letter {
    text-transform: uppercase;
}

.elem-list_item-desc {
    padding: 0 5px 10px;

    border-bottom: 1px solid @warning-color;

    p {
        font-size: 16px;
    }
}

.desc_bottom {
    margin-top: 30px;
}

.desc_top {
    margin-bottom: 30px;
}

.elem-list_item-price-block {
    padding: 10px 5px;

    p {
        position: relative;
    }


    .elem-list_item-price-sale {
        position: absolute;
        text-decoration: line-through;
        top: -13px;
        left: 43px;
    }

    .checkbox {
        margin-bottom: 25px;

        label {
            color: @link-color;
        }
    }
}

.price__btns {
    text-align: center;
}

.price__btn {
    margin-bottom: 10px;
}

form {

    input,
    textarea {
        border: 1px solid @secondary-color;
        border-radius: .3em;
        padding: 10px 15px;
        width: 100%;
        box-sizing: border-box;

        &:hover,
        &:focus {
            outline: none;
            border-color: @primary-color;
        }
    }

    input[type=submit] {
        width: auto;
    }
}

.form__elem {
    margin-bottom: 10px;
}

.bx-wrapper .bx-viewport {
    display: inline-block;
    background: transparent;
}

.bx-wrapper {
    margin: 0 auto;
}

.copyright {
    margin-bottom: 0;

    img {
        margin-left: 5%;

        max-width: 30px;
    }

    @media all and (max-width: 450px) {
        font-size: 11.5px;
    }
}

/*.map_link {
    @media all and (max-width: 450px) {
        font-size: 9px;
    }
}*/

.footer {
    display: flex;
    flex-direction: column;
    padding-bottom: 80px;

    background-color: @primary-color !important;
    background-image: url('@{images-folder}blue-texture.jpg') !important;
    background-position: center;
    background-size: cover;

    font-family: @special-font-family;
    font-size: 12.96px;
    color: @font-revers-color;

    @media all and (min-width: 768px) {
        padding-top: 20px;
        padding-bottom: 0;

        font-size: 14px;
    }
}

.footer a {
    color: @font-revers-color;
}

.footer__content:not(:last-child) {
    margin-bottom: 10px;
}

.footer-contacts__item:not(:last-child) {
    padding-bottom: 15px;
    margin-bottom: 15px;

    border-bottom: 1px solid @base-color;
    border-image: 1 linear-gradient(to right, @base-color 0, @base-color 90px, transparent 0, transparent 100%);
}

.footer-contacts__item p {
    margin-bottom: 0;
}

.footer-contacts__title {
    font-weight: bold;
    font-size: 1.5em;

    @media all and (max-width: 450px) {
        font-size: 12.96px;
    }
}

.footer-contacts__item .footer-contacts__title {
    margin-bottom: 7px;
}

.footer-list {
    list-style: none;
}

.footer-list:not(:last-child) {
    margin-bottom: 10px;

    @media all and (min-width: 768px) {
        margin-bottom: 0;
    }
}

.cards {
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    &__item {
        margin-right: 25px;
        margin-bottom: 10px;

        @media screen and (max-width: 768px) {
            max-width: 46px;
            margin-right: 8px;
        }

        &:last-of-type {
            margin-right: 0;
        }
    }
}

.xs-hidden,
.hide_element,
.sm-block,
.md-block,
.lg-block {
    display: none;
}

.doc-list {
    padding-right: 49%;
    min-height: 80px;

    counter-reset: counter;

    font-size: 11px;

    background-image: url('@{images-folder}docs.jpg');
    background-size: 45%;
    background-repeat: no-repeat;
    background-position: 94% center;

    &__title {
        margin-bottom: 30px;

        font-family: @special-font-family;
        text-transform: uppercase;

        @media all and (max-width: 768px) {
            margin-bottom: 10px;
            padding-top: 9px;
        }
    }

    &__item {
        margin-bottom: 3px;

        color: #002fb7;

        &::before {
            counter-increment: counter;
            content: counter(counter) '. ';
        }

        @media screen and (max-width: 399px) {
            font-size: 11px;
        }
    }

    &__link {
        text-decoration: underline;
        color: #002fb7;

        border: none;
        background-color: transparent;

        &:hover {
            text-decoration: none;
        }
    }

    @media screen and (min-width: 370px) {
        min-height: 90px;
    }

    @media screen and (min-width: 430px) {
        min-height: 100px;

        font-size: 12px;
    }

    @media screen and (min-width: 490px) {
        min-height: 110px;

        font-size: 14px;
    }

    @media screen and (min-width: 508px) {
        min-height: 120px;
    }

    @media screen and (min-width: 560px) {
        min-height: 130px;

        font-size: 16px;
    }

    @media screen and (min-width: 600px) {
        background-size: contain;
    }
}

.ul,
ol {
    list-style: none;
}

ol {
    counter-reset: ol_list;

    li::before {
        display: inline;
        counter-increment: ol_list;
        content: counter(ol_list) '. ';

        color: inherit;
        font-size: inherit;
        line-height: inherit;
    }
}

.ul li {
    position: relative;
    padding-left: 25px;
    padding-bottom: 7px;

    overflow: hidden;

    &::before {
        left: 3px;
        top: 5px;
        content: '';
        position: absolute;

        height: 8px;
        width: 8px;

        background-color: @primary-color;
        border-radius: 50%;
    }
}

.ul-col-2 {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.ul-col-2 li {
    width: 49%;
}

.tech-description {
    margin-bottom: 10px;
}

/* Base for label styling */
[type="checkbox"]:not(:checked),
[type="checkbox"]:checked,
[type="radio"]:not(:checked),
[type="radio"]:checked {
    position: absolute;
    left: 0;
    opacity: 0;
    width: 0px;
}

.good-media-footer__item--compare {

    [type="checkbox"]:not(:checked) + label,
    [type="checkbox"]:checked + label {
        font-family: @title-font-family;
        font-size: 10.8px;
        font-weight: bold;
        color: @base-color-light;

        @media all and (min-width: 400px) {
            font-size: 14px;
        }
    }


}

/* checked mark aspect changes */
[type="checkbox"]:not(:checked) + label:after,
[type="radio"]:not(:checked) + label:after {
    opacity: 0;
}

[type="checkbox"]:checked + label:after,
[type="radio"]:checked + label:after {
    opacity: 1;
}

[type="checkbox"]:disabled + label,
[type="radio"]:disabled + label {
    opacity: .3;
}

.md-visible {
    display: none;
}

.pop-up_form {
    padding: 15px;

    text-align: center;
    font-size: 16px;

    background: @base-color;

    position: relative;
    .z-index(18);

    p {
        margin-bottom: 15px;
    }

    form {
        text-align: left;
        font-size: 14px;
    }

    input,
    textarea {
        border-color: @link-color;
    }
}

.form_el_invalid,
input[type="text"].form_el_invalid,
.wrapped_select,
select.wrapped_select {
    border: 1px solid @link-color  !important;
}

.lightTip {
    .content {
        background-color: @base-color;
        border: 6px solid @link-color;
        border-radius: 5px;
        color: @body-text-color;
        padding: 9px 18px 10px 18px;
    }

    .bottom {
        position: absolute;
        bottom: -8px;
        left: 50%;

        width: 29px;
        height: 14px;
        overflow: hidden;

        &::after {
            content: '';
            position: absolute;
            bottom: 6px;
            left: -2px;
            width: 29px;
            height: 29px;
            background-color: @base-color;
            border: 6px solid @link-color;
            transform: rotate(45deg);
        }
    }
}

.fancybox-close {
    .z-index(189);
}

.fancybox-close {
    background: url('@{images-folder}close-2.png') no-repeat !important;
    top: 2px;
    right: 2px;
    width: 21px;
    height: 21px;

    transition: .3s;

    &:hover {
        transform: scaleX(-1);
    }
}

.fancybox-close {
    top: 7px;
    right: 7px;

    background-image: url('@{icons-folder}close-form.svg') !important;
    background-size: contain !important;
}

.fancybox-overlay {
    .z-index(100);

    background-color: @shadow-color;
}

.fancybox-inner {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100% !important;

    text-align: center;
    background: @base-color;

    zoom:1;
    overflow: hidden;

}

.fancybox-opened {
    .z-index(105) !important;
}

.hint {
    background-color: @primary-color;
    border-radius: 15px;
    height: 13px;

    font-size: 8px;
    color: @font-revers-color;

    padding: 2px 5px;
    margin-left: 3px;

    position: relative;
    top: -5px;

    &::after {
        content: '?';
    }
}

.hint-wrap {
    background-color: @base-color;
    box-shadow: 1px 1px 3px @shadow-color;
    border: solid 1px @secondary-color;

    color: @font-color;
    font-size: 12px;
    text-align: left;

    display: block;
    width: 240px;

    padding: 10px;

    position: absolute;
    top: 17px;
    left: -205px;

    visibility: hidden;
    opacity: 0;

    .z-index(15);
    transition: opacity 0.4s;

    &::before {
        content: '';

        background-color: @base-color;
        border-color: @secondary-color;
        border-style: solid;
        border-width: 0 1px 1px 0;

        width: 5px;
        height: 5px;

        position: absolute;
        top: -3px;
        left: 207px;

        transform: rotate(-135deg);
        .z-index(16);
    }

    & > span {
        font-weight: normal;
        line-height: 18px;

        display: block;
    }
}

.hint-wrap_left {
    left: -20px;

    &::before {
        left: 23px;
    }
}

.hint:hover .hint-wrap,
.hint:active .hint-wrap {
    opacity: 1;
    visibility: visible;
}

.media-imgs {
    .media-img-greate {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 15px;
        max-width: 100px;
        flex-grow: 1;

        @media all and (min-width: 380px) {
            max-width: 120px;
        }

        @media all and (min-width: 470px) {
            max-width: none;
        }

        @media all and (min-width: 768px) {
            margin-bottom: 0;
            height: 100%;
        }
    }
}

.bx-wrapper {
    position: relative;

    margin: 0 auto;
    padding: 0;
}

.bx-wrapper .bx-controls-direction a {
    position: absolute;
    top: 50%;
    margin-top: -16px;
    outline: 0;
    width: 32px;
    height: 32px;
    color: transparent;
    font-size: 0px;
    .z-index(9);
}

.bx-wrapper .bx-controls-direction {

    .bx-prev,
    .bx-next {
        top: 50%;
        .z-index(3);

        .sizes(18px, 27px);
        margin-top: 0;

        background-image: url('@{icons-folder}arrow-blue.svg');
        background-size: contain;
        background-repeat: no-repeat;

        transform: translate(0, -50%);

        &.disabled {
            opacity: 0;
        }
    }

    .bx-next {
        right: -16px;

        transform: translate(0, -50%) rotate(180deg);
    }

    .bx-prev {
        left: -22px;
    }
}

.fancybox {
    &-wrap {
        @media all and (max-width: 768px) {
            top: 90px !important;
            left: 50% !important;
            transform: translate(-50%, 0) !important;

            /*width: 75% !important;*/
        }

        .fancybox-prev span {
            left: -70px !important;
        }

        .fancybox-next span {
            right: -70px !important;
        }
    }

    &-inner {
        margin: 0 auto !important;
        /*width: 100% !important;
        height: auto !important;*/
    }

    &-image {
        height: auto !important;
        width: auto !important;
    }
}

.elem-list_item-price-block {
    padding: 0;
}

.price-good {
    font-family: @title-font-family;
    font-size: 17.2px;
    font-weight: 900;
    color: @body-text-color;

    margin-bottom: 0;

    @media all and (min-width: 500px) {
        font-size: 20px;
    }

    @media all and (min-width: 768px) {
        font-size: 22px;
    }

    &.discount {
        text-decoration: line-through;
        color: #525252;
    }
}

.price-good .elem-list_item-price {
    font-size: 28px;
}

.price-good .no-price {
    font-size: 0.8em;
}

.good-media-footer.flex__row-full .price-good {
    @media screen and (max-width: 390px) {
        font-size: 17px;
    }
}

.good-media-footer.flex__row-full .btn-active {
    @media screen and (max-width: 390px) {
        font-size: 11px;
    }
}

.good-media-footer.flex__row-full .btn-active {
    padding: 5px 10px 5px 6px;
    align-self: center;

    @media all and (min-width: 768px) {
        align-self: flex-start;
    }
}

.good-media-footer.flex__row-full a {
    text-align: center;

    & .icons--compare {
        @media screen and (max-width: 390px) {
            background-position: -3px -50px;
        }
    }

    @media screen and (max-width: 390px) {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;

        font-size: 10px;
    }

    @media all and (min-width: 768px) {
        text-align: left;
    }
}

.good-media-footer.flex__row-full {
    align-items: stretch;
    flex-wrap: wrap;


    @media all and (min-width: 768px) {
        align-items: center;
        flex-direction: row-reverse;
    }

    .good-media-footer__item.flex__column-full:last-of-type {
        @media all and (min-width: 768px) {
            width: 100%;
            flex-direction: row;
        }

        .checkbox {
            @media all and (min-width: 768px) {
                width: auto;
                margin-bottom: 0;
                align-self: flex-end;
            }
        }
    }
}

.elem-list_item-price-block .price__btn .btn {
    width: auto;

    font-weight: bold;
    font-size: 1.2em;
}

.elem-list_item-price-block .price__btn {
    margin-bottom: 25px;
}

table {
    border-collapse: collapse;

    font-size: 14px;
    line-height: 1.1;
}

.table:not(.table_min) {
    table-layout: fixed;
}

.tr-img td {
    vertical-align: middle;
}

.td-price {
    color: @body-text-color;
    font-weight: bold;
}

td {
    border: 1px solid @secondary-color;
    padding: 7px 10px;
}

.table thead td,
.table thead th {
    background-color: @primary-color;
    color: @font-revers-color;
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    padding: 3px 0 2px;
}

.table td:before {
    content: attr(data-name);
    color: @primary-color;
    font-weight: bold;
    width: 50%;
    text-align: left;
}

.table thead td.tabl-hidden,
.table thead th.tabl-hidden,
.table-less-width td.tabl-hidden {
    display: none;
}

.table td {
    border: none;
}

.table td,
.table th {
    border: 1px solid @base-color;
    border-top: none;
    border-bottom: none;
}

.table tbody td {
    border-top: none;
    border-bottom: none;
}

.table_min {
    border: 1px solid @secondary-color;
    border-top-color: @primary-color;
}

.table_min tbody td {
    border-color: @secondary-color;
    border-width: 0 1px;
}

.table:not(.table_min) tbody td {
    display: flex;
    flex-direction: column;
}

.table td:first-child {
    font-size: 16px;
    font-weight: bold;
    color: white;
    text-align: left;
    padding-left: 25px;
}

.table:not(.table_min) tbody td:not(:first-child) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.table td:first-child a {
    color: @font-revers-color;
}

.table:not(.table_min) tbody td:nth-child(odd),
.table_min tbody tr:nth-child(even) td {
    background-color: @secondary-color;
}

.table:not(.table_min) tbody td:first-child {
    background-color: @primary-color;
}

.b-pagination {
    .b-pagination_numbers {
        display: inline-block;
        vertical-align: top;

        li {
            height: 36px;
            width: auto !important;
            min-width: 36px;
            text-align: center !important;
            background-color: @base-color;
            line-height: 2;
            box-shadow: none !important;
            margin-right: 0;
            display: inline-block;
            vertical-align: top;

            a {
                color: @link-color;
                font-size: 18px;
                text-decoration: none;
                display: block;
                border: 1px solid transparent;

                &:hover {
                    border: 1px solid @secondary-color;
                }
            }

            &.active {
                a {
                    background-color: @primary-color;
                    border: 1px solid @primary-color;
                    color: @font-revers-color;
                }
            }
        }
    }

    .b-pagination_prev,
    .b-pagination_next {
        display: inline-block;
        vertical-align: top;
        height: 36px;
        width: 36px;
        background-color: @base-color;

        a {
            color: transparent;
            display: block;
            height: 36px;
            width: 36px;
            border: 1px solid @base-color;

            &:hover {
                cursor: pointer;
                border: 1px solid @secondary-color;
            }
        }
    }

    .b-pagination_prev {
        a {
            background: url('@{images-folder}arrow-prev.png') center center no-repeat;
        }
    }

    .b-pagination_next {
        a {
            background: url('@{images-folder}arrow-next.png') center center no-repeat;
        }
    }

    .b-pagination_all {
        display: none;
    }
}

.table {
    width: 100%;

    td,
    th,
    .not-first:first-child {
        text-align: center;
        padding: 10px 5px;
        vertical-align: middle;

        a {
            &.a-green {
                padding-left: 15px;
                border-bottom: none;

                &:before {
                    content: ">";
                    bottom: 0px;
                }
            }
        }
    }

    .tbody {
        td {
            &:first-child {
                background-color: @base-color;
            }
        }
    }
}

.table.scroll {
    .tbody {
        max-height: 300px;
        overflow-y: scroll;

        & > div {
            display: table;
        }
    }

    .tr {
        text-align: left;
        display: flex;
        flex-direction: row;

        .td {
            display: table-cell;
            vertical-align: middle;
            text-align: left;
            padding: 2px 7px;
            font-size: 10px;
            line-height: @base-line-height;
            border-bottom: 1px solid @secondary-color;

            &:first-child {
                width: 59%;
                border-right: 1px solid @secondary-color;
                border-left: 1px solid @secondary-color;
            }

            &:nth-child(2) {
                width: 15%;
                text-align: center;
            }

            &:last-child {
                flex-grow: 2;
                border-left: 1px solid @secondary-color;
                border-right: 1px solid @secondary-color;
            }
        }
    }
}

#description_top {
    a {
        color: @link-color;
        margin: 5px 0 30px;
        display: unset;
        &.baxi-type-link {
            color: #001034;
            font-weight: bold;
            text-decoration: underline;
            &:hover {
                text-decoration: none;
            }
        }
    }
}

.fake_table {
    margin-top: 53px !important;

    tr {
        background-color: @info-color;
        font-size: 14px;
    }
}

.form {
    width: 100%;
}

.w-120 {
    width: 120%;
}

.b-r20 {
    border-radius: 20px;
}

.no-link {
    pointer-events: none;
}

.header {
    position: fixed;
    top: 0;
    .z-index(100);

    width: 100%;
}

.header__item-cont {
    background-color: @base-color;

    color: @font-color;
}

.top-contacts {
    display: flex;
    align-items: center;
    justify-content: space-between;

    padding: 5px 10px;

    position: relative;
    .z-index(6);
}

.top-contacts__contacts-link a {
    color: @font-color;
    text-decoration: underline;

    &:hover {
        text-decoration: none;
    }
}

.sidr .top-contacts {
    flex-direction: column;
}

.a-cont {
    color: @font-color;
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
}

.top-contacts .a-tel {
    font-size: 18px;
    font-weight: 500;
    line-height: 1;
}

.header__item-search {
    background-color: @primary-color;
}

.header__item-menu {
    background-color: @base-revers-color;

    display: none;
}

.header-menu .ul-lvl-1 {
    list-style: none;
}

.header-menu .a-lvl-1 {
    font-size: 16px;
    color: @font-revers-color;
    text-transform: uppercase;

    padding: 3px 10px;
    display: inline-block;

    &:hover,
    &.active {
        text-decoration: underline;
    }
}

.top-search {
    padding: 5px 7px 15px;
}

.items-list {
    display: flex;
    grid-template-columns: 1fr;


    @media (min-width: 610px) {
        grid-template-columns: 1fr 1fr;
        grid-gap: 20px;
    }

    @media (min-width: 915px) {
        grid-template-columns: 1fr 1fr 1fr;
    }

    &--scroll {
        display: flex;

        overflow-x: auto;

        .elem-list_item {
            display: flex;
            flex-direction: column;
            width: 120px;
            flex-shrink: 0;
            margin-right: 30px;

            @media screen and (min-width: 768px) {
                width: 380px;
                margin-right: 20px;
            }
        }
    }
}

.nav {
    margin-bottom: 0;
    /*
    .dropdown {
        .a-lvl-1 {
            &::after {
                content: '';
                position: absolute;
                top: 0;
                right: 10px;

                display: block;
                height: 20px;
                width: 20px;

                border-right: 2px solid white;
                border-bottom: 2px solid white;

                transform: rotate(45deg) translate(3px, 11px);

                @media all and (min-width: 768px) {
                    transform: rotate(45deg) translate(0px, 7px)
                }
            }
        }
    }*/
}

.nav .li-lvl-1:last-of-type .a-lvl-1 {
    border-bottom: none;
}

.nav .ul-lvl-1,
.nav .ul-lvl-2,
.nav .ul-lvl-3 {
    list-style: none;
}

.nav .ul-lvl-1 {
    padding-bottom: 0;
    margin-bottom: 0;
}

.nav .a-lvl-1 {
    position: relative;

    display: block;
    width: 100%;
    padding: 10px 10px 10px 40px;
    margin-bottom: 0;

    text-decoration: none;
    text-align: center;
    font-size: 16px;
    color: @font-revers-color;

    border-bottom: 2px solid @font-revers-color;

    span {
        display: inline;
        padding: 0;

        color: @font-revers-color;
    }
}

.sidr ul li span.count-wrapper {
    position: absolute;
    top: 50%;
    right: 20%;

    display: inline-block;
    padding: 2px 8px;
    margin-left: 15px;

    color: @primary-color;
    background-color: @font-revers-color;
    border-radius: 15px;

    transform: translate(0, -50%);

    @media all and (min-width: 768px) {
        right: 30%;
    }
}

.nav a.a-lvl-1:hover {
    text-decoration: underline;
}

.nav .ul-lvl-2 {
    padding: 10px;
}

.nav .li-lvl-2 {
    &::before {
        content: '';

        display: inline-block;
        vertical-align: middle;
        width: 7px;
        height: 7px;
        margin-right: 3px;

        background-color: @font-revers-color;
        border-radius: 50%;
    }
}

.nav .producers .ul-lvl-2 {
    display: flex;
    flex-wrap: wrap;

    .li-lvl-2 {
        width: 30%;
        min-width: 140px;
    }
}

.nav .catalog .ul-lvl-2 {
    display: flex;
    flex-wrap: wrap;

    .li-lvl-2 {
        width: 45%;
        min-width: 140px;
    }
}

.nav .a-lvl-2 {
    text-decoration: none;
    font-size: 14px;
    line-height: 1.4;
    color: @font-revers-color;

    padding: 0;
    margin-bottom: 0;

    display: inline-block;
}

.nav a.a-lvl-2.active,
.nav a.a-lvl-2:hover {
    text-decoration: underline;
}

.nav .a-lvl-3 {
    text-decoration: none;
    font-size: 14px;
    color: @font-revers-color;

    padding: 5px 35px;

    display: inline-block;
}

.nav a.a-lvl-3:hover,
.nav a.a-lvl-3.active {
    font-weight: bold;
}

.top-block {
    display: none;
    @media all and (min-width: 1100px) {
        margin-top: 53px;
    }
}

.header {
    width: 100%;
}

.sidr .sidr-inner {
    padding: 0;
}

.left-banner {
    padding: 17px 15px 19px;
    margin-bottom: -5px;

    border-bottom: 1px dashed #FFFFFF;

    &:hover {
        text-decoration: underline;
    }

    &__text {
        margin: 14px 0 0;
        color: #ffffff;
        font-weight: bold;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.03em;
        text-transform: uppercase;
    }
}

.bx-pager {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.bx-pager-link {
    font-size: 18px;
    color: @font-color;
    text-decoration: none;

    padding: 2px 9px;

    &:hover {
        color: @font-revers-color;

        background-color: @primary-color;
    }
}

.bx-pager-link.active {
    font-weight: bold;
}

.bx-pager-item {
    margin: 2px;
}

.slider-with-pager .bx-pager {
    position: relative;
    .z-index(3);
}

.slider-with-pager .bx-pager-link {
    font-size: 0;

    display: block;
    width: 10px;
    height: 10px;

    border: 1px solid @primary-color;
    border-radius: 50%;

    padding: 0;

    &.active,
    &:hover {
        background-color: @primary-color;
    }
}

.good-media__item {
    background-color: @base-color;

    margin-bottom: 15px;
    padding: 20px 10px;
}

.tech-desc_tit {
    font-weight: bold;
}

.good-chars {
    margin-bottom: 15px;

    font-family: @base-font-family;
    color: @body-text-color-light;

    &-title {
        margin: 0 0 5px;

        font-weight: bold;
        font-size: 19px;
        color: @body-text-color-light;
    }

    &__item {
        display: flex;
        justify-content: space-between;
        /*align-items: flex-start;*/
        align-items: stretch;

        font-weight: bold;
        font-size: 11px;

        /*border-bottom: 1px dotted @base-color-light;*/

        @media all and (min-width: 380px) {
            font-size: 12px;
        }

        @media all and (min-width: 430px) {
            font-size: 14px;
        }

        @media all and (min-width: 600px) {
            font-size: 15px;
        }

    }

    &__elem {
        background-color: @base-color;

        /*position: relative;
        bottom: -4px;*/

        @media screen and (min-width: 992px) {
            /*bottom: -8px;*/
        }
    }
}

.link-char {
    color: @primary-color;
    font-size: 14px;
}

.elem-list_item-img {
    position: relative;

    padding: 5px;
    margin-bottom: 10px;

    text-align: center;
}

.media-imgs-more img,
.media-img-greate {
    border: 1px solid #cecbcb;
}

.image-link {
    overflow: hidden;
}

.image-link .image-img {
    max-height: 150px;

    transition: .3s;
}

.image-link:hover .image-img {
    transform: scale(1.1, 1.1);
}

.link_plus {
    position: relative;
}

.elem-list_item-img .checkbox {
    margin-top: 7px;
}

.goods-table .elem-list_item-img {
    background-color: transparent;
}

.breadcrumbs {
    margin: 5px 0;

    font-family: @title-font-family;

    list-style: none;
}

.breadcrumbs li {
    display: inline-block;

    &::after {
        content: '>';
        margin-left: 5px;
    }

    &:last-child::after {
        content: '';
    }
}

.breadcrumbs a {
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
}

.breadcrumbs a,
.breadcrumbs li {
    font-size: 16px;
    font-weight: bold;
    text-transform: uppercase;
    color: @dark-secondary-color;
}

#preloader {
    display: none;
}

.preloader {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    display: flex;
    justify-content: center;
    align-items: center;

    .z-index(12);
}

.show-all {
    margin-bottom: 20px;
    padding: 0;

    text-decoration: underline;
    font-size: 1em;
    color: @primary-color;
    font-family: @base-font-family;

    background-color: transparent;
    background-image: none;
    border: none;

    cursor: pointer;

    &::after {
        content: '';

        display: inline-block;

        margin-left: 5px;

        height: 10px;
        width: 10px;

        border-right: 1px solid @primary-color;
        border-bottom: 1px solid @primary-color;

        transform: rotate(45deg);
        transform-origin: 100% 100% 0;
        transition: .5s;
    }

    &:hover {
        text-decoration: none;
        outline: none;
    }

    &:active {
        outline: none;
    }
}

.content-404 {
    max-width: 100%;
    width: 380px;

    margin: 0 auto;
}

.good-media .btn {
    width: 100%;
}

.comparison-table__name {
    color: @font-color;
    font-weight: bold;
}

.comparison-table__title,
.left-table .comparison-table__title {
    background-color: @primary-color;

    color: @font-revers-color;
    font-size: 18px;

    border: 1px solid @primary-color;
}

.contect-with-img {
    display: flex;
    flex-direction: column;

    background-color: @base-color;
    max-width: 100%;

    margin-bottom: 25px;
}

.contect-with-img__img,
.contect-with-img__noimg {
    text-align: center;
}

.contect-with-img__desc,
.contect-with-img__noimg {
    padding: 15px 10px;
}

.button-circle-arrow(@bg-color: @primary-color, @arrow-color: @font-revers-color, @size: 50px, @arrow-width: 2px) {
    background-color: @bg-color;

    display: block;
    height: @size;
    width: @size;

    border-radius: 50%;
    border: none;

    position: relative;
    transition: .5s;

    &::after {
        content: "";
        position: absolute;
        top: 14px;
        left: 16px;

        display: block;
        height: 12px;
        width: 12px;

        box-sizing: border-box;
        border: @arrow-width solid @arrow-color;
        border-left-color: transparent;
        border-top-color: transparent;

        transform: rotate(-45deg);
    }

    &::before {
        content: "";
        position: absolute;
        top: 19px;
        left: 10px;

        width: 20px;
        height: @arrow-width;
        background-color: @arrow-color;

        margin-right: 10px;
    }

    &:hover,
    &:focus {
        background-image: linear-gradient(to top, darken(@bg-color, 10%), darken(@bg-color, 10%) 100%);
        outline: none;

        transform: scale(1.2);
    }
}

.link-next {
    .button-circle-arrow(@bg-color: @warning-color, @size: 40px);
}

.price {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.sidr ul {
    margin: 0;
}

.top-contacts__title,
.sidr .top-contacts__title {
    margin: 0 0 10px;

    font-size: 16px;
    font-family: @special-font-family;
    color: @body-text-color;
    text-transform: uppercase;
    text-align: center;

    padding: 3px 10px;
    display: block;
    width: 100%;
}

.top-contacts__title {
    display: none;
}

.sidr .top-contacts__title {
    display: block;
}

.sidr .top-contacts {
    padding: 0 10px;

    align-items: baseline;
}

.sidr .top-contacts__contacts-link {
    display: none;
}

.sidr .call-me {
    margin: 0 auto;

    display: block;
}

.contacts-title,
.sidr .contacts-title {
    font-size: 11px;
    color: @font-color;
    line-height: 1;

    margin-bottom: 10px;
}

.contacts-title,
.top-contacts__item-email,
.top-contacts__item-callme {
    display: none;
}

.sidr .contacts-title,
.sidr .top-contacts__item-email,
.sidr .top-contacts__item-callme {
    display: block;
}

.contacts-title_main,
.sidr .contacts-title_main {
    font-weight: bold;
    color: @primary-color;
}

.sidr .top-contacts__item {
    margin-bottom: 20px;

    width: 100%;
}

.top-contacts .a-cont {
    font-size: 14px;
    font-weight: normal;
}

.form-search {
    position: relative;

    width: 100%;
}

.search {
    background-color: @base-color;
    border: 1px solid @link-color;
    border-radius: @base-border-radius;

    width: 100%;
    padding: 5px;
    padding-right: 50px;
}

.form-search_big .search {
    border: none;
    border-radius: 0;

    padding-right: 5px;
}

.form-search_big .search:nth-child(1) {
    padding-bottom: 0;
}

.form-search_big .search:nth-child(2) {
    padding-top: 0;
}

.search__hidden {
    position: absolute;
    top: calc(100% - 9px);

    border-top: none;
    border-radius: 0 0 @base-border-radius @base-border-radius;

    padding-top: 0;

    .z-index(4);
}

.search__block {
    display: flex;
    flex-wrap: wrap;
}

.search__title {
    text-align: center;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: bold;

    width: 100%;
}

.search__item {
    width: 49%;

    display: flex;
    align-items: center;

    font-size: .9em;

    padding-bottom: 10px;
}

.search__item_long {
    align-items: initial;
    flex-direction: column;
}

.search__item_long .search__item {
    width: 100%;
}

.search__item:not(:nth-child(2n)) {
    padding-right: 5px;
    margin-right: 2%;

    border-right: 1px solid @secondary-color;
}

.search__submit {
    width: 100%;
    justify-content: flex-end;

    padding-bottom: 0;
}

.form-search_big .search__submit {
    justify-content: center;

    margin-top: 15px;
}

.search_button-down {
    position: absolute;
    right: 5px;
    top: 6px;
}

.search__item label {
    margin-right: 5px;

    flex-grow: 1;
}

.search__item .input-search {
    border-color: @link-color;
    box-shadow: inset 0px 2px 6px 0px @shadow-color;

    padding: 3px;
    max-width: 40px;
}

.block_elem-list {
    padding-bottom: 0;
}

/*.media-img-greate .elem-list_item-img img {
    max-height: 200px;

    @media all and (min-width: 768px) {
        max-height: none;
    }
}*/

.media-img-greate .elem-list_item-img {
    margin-bottom: 0;
    width: 100%;
    height: 100%;
}

@media (min-width: 425px) {
    .bx-wrapper img:not(.icons-svg) {
        margin: 0 auto;
    }

    .elem-list_item-img img {
        width: auto;
    }

    .gallery__item {
        width: 49%;
    }
}

@media (min-width: 500px) {
    .elem-list_item-price-block {
        display: flex;
        justify-content: space-between;
        align-items: center;

        margin-bottom: 10px;
    }

    .elem-list_item-price-block .price__btn {
        margin-bottom: 0;
    }
}

@media (min-width: 600px) {
    .img-l {
        float: left;
        margin: 0 20px 15px 0;
    }

    .img-r {
        float: right;
        margin: 0 0 15px 20px;
    }

    .after-float {
        display: flex;
        flex-direction: column;
    }

    .sm-block {
        display: block;
    }

    td.sm-block {
        display: table-cell;
    }

    .j-bxslider-video {
        li {
            iframe {
                height: 300px;
            }
        }
    }

    .h-contacts {
        padding: 0;
    }

    .xs-hidden {
        display: block;
    }

    .a-cont_without-icon {
        padding-left: 0;

        &::before {
            display: none;
        }
    }

    .sm-hidden {
        display: none;
    }

    .fake_table {
        margin-top: 119px !important;
    }

    .footer-contacts {
        display: flex;
    }

    .footer-contacts__item:first-child {
        flex-grow: 1;
    }

    .footer-contacts__item:not(:last-child) {
        border: none;

        padding-bottom: 0;
        margin-bottom: 0;
    }

    .footer-contacts__item {
        padding: 10px 50px 10px 0;
    }

    .footer-contacts__item:not(:last-child) {
        margin-right: 50px;

        border-right: 1px solid @base-color;
    }

    .search__block.search__hidden {
        margin-bottom: 0;
    }

    .search__title {
        width: 18%;

        margin-bottom: 0;
        padding-right: 10px;
        margin-right: 2%;
        border-right: 1px solid @secondary-color;

        display: flex;
        align-items: center;
    }

    .form-search_big .search__title {
        width: 100%;

        padding-right: 0;
        margin-right: 0;
        margin-bottom: 10px;
        border-right: 0;

        justify-content: center;
    }

    .search__title + .search__item {
        width: 29%;
    }

    .search__hidden .search__item,
    .form-search_big .search__item {
        width: 49%;

        margin-bottom: 20px;
    }

    .search__hidden {
        padding-top: 17px;
    }

    .search__hidden .search__submit,
    .form-search_big .search__submit {
        width: 100%;

        margin-bottom: 0;
    }

    .search__item:not(:nth-child(2n)) {
        padding-right: 0;
        border-right: 0;
    }

    .search__item {
        padding-bottom: 0;
    }

    .search__item_long {
        flex-direction: row;
        align-items: center;
    }

    .search__item_long .search__item {
        flex-grow: 1;
        justify-content: flex-end;

        margin-bottom: 0;
    }

    .search__item_long .search__item label {
        flex-grow: 0;

        margin-left: 20px;
    }

    .table tbody td,
    .table:not(.table_min) tbody td {
        display: table-cell;
        border: 1px solid @secondary-color;
        border-top: none;
        border-bottom: none;
    }

    .table thead td:first-child {
        border-left: 1px solid @primary-color;
    }

    .table thead td:last-child {
        border-right: 1px solid @primary-color;
    }

    .table-less-width .table thead td {
        font-size: 18px;
    }

    .table-less-width .table thead td:first-child,
    .table-less-width .table tbody tr td:first-child {
        text-align: left;
        padding-left: 30px;
    }

    .table-less-width {
        width: 65%;
        margin: 0 auto;
    }

    .table td:before {
        display: none;
    }

    .table tbody td:nth-child(odd),
    .table:not(.table_min) tbody td:nth-child(odd) {
        background-color: white;
    }

    .table thead td.tabl-hidden,
    .table tbody td:not(:first-child),
    .table thead th.tabl-hidden,
    .table-less-width td.tabl-hidden {
        display: table-cell;
    }

    .table tbody td:first-child,
    .table:not(.table_min) tbody td:first-child {
        background-color: white;
        color: @primary-color;
        text-align: center;
        padding-left: 5px;
    }

    .table tbody tr:nth-child(even) td:first-child {
        background-color: @secondary-color;
    }

    .table tbody td:not(:first-child),
    .table:not(.table_min) tbody td:not(:first-child) {
        display: table-cell;
    }

    .table tbody tr:nth-child(even) td:nth-child(odd) {
        background-color: @secondary-color;
    }

    .table tr:nth-child(even) {
        background-color: @secondary-color;
    }

    .table .border-table {
        border-bottom: 1px solid white;
    }

    .tabl-name {
        width: 14%;
    }

    .tabl-key {
        width: 17%;
        text-align: left;
    }

    .tabl-mob-key {
        display: block;
        width: 100%;
    }

    .button-table {
        padding: 4px 17px;
    }

    .table tr td.tabl-montazh-but {
        padding: 6px 5px;
    }
}

@media (min-width: 768px) {

    .h1,
    .h2,
    .h3,
    h1,
    h2,
    h3 {
        margin-bottom: 20px;
    }

    body {
        font-size: 14px;
    }

    .table.scroll {
        .tr {
            .td {
                font-size: 13px;
            }
        }
    }

    .j-bxslider-video li iframe {
        height: 400px;
    }

    .h-pt10-lg {
        padding-top: 10px;
    }

    .h-pt30-lg {
        padding-top: 30px;
    }

    .contact-form {
        display: flex;
        align-items: center;
    }

    .elem-list_item-name {
        font-size: 18px;
        flex-grow: 1;
    }

    .elem-list_listing .items-list {
        flex-direction: column;
    }

    .elem-list_listing .elem-list_item {
        max-width: 100%;
        margin-left: 0;
        margin-right: 0;

        flex-direction: row;
    }

    .elem-list_listing .elem-list_item-img {
        width: 200px;

        flex-shrink: 0;
        flex-grow: 0;
    }

    .elem-list_listing .elem-list_item-descr {
        flex-grow: 1;

        padding: 20px 10px;
    }

    .elem-list_listing .elem-list_item-name {
        text-align: left;
    }

    .elem-list_listing .elem-list_item-desc {
        padding: 0;

        border: none;
    }

    .elem-list_listing .price {
        margin: 20px 0;
        padding: 0 25px;

        border-left: 1px solid @secondary-color;
    }

    .contect-with-img {
        flex-direction: row;

        margin-bottom: 35px;
    }

    .contect-with-img__img,
    .contect-with-img__noimg {
        flex-shrink: 0;

        max-width: 40%;
    }

    .contect-with-img__noimg-all-width {
        min-width: 100%;
    }

    .contect-with-img__desc {
        flex-grow: 1;

        width: 60%;
    }

    .flexbox-wrapper {
        display: flex;
        align-items: center;
    }

    .flexbox-jc-sb {
        justify-content: space-between;
    }

    .flexbox-fs-0 {
        flex-shrink: 0;
    }

    .goods-desc_block {
        font-size: 0.87em;
    }

    .pop-up_form form {
        width: 500px;
    }

    .banner-hide {
        display: none;
    }
}

@media (min-width: 800px) {
    .table.scroll .thead {
        width: ~'-webkit-calc(100% - 17px)';
        width: ~'-moz-calc(100% - 17px)';
        width: ~'calc(100% - 17px)';
    }

    .short-inputs {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    .short-input {
        width: 48.5%;
    }

    .media-imgs {
        width: 45%;
        padding: 20px 15px;
        box-sizing: border-box;

        flex-shrink: 0;
    }

    .good-media__item:nth-child(odd) {
        margin-right: 25px;
    }

    .goods-desc_block {
        flex-grow: 1;

        padding: 10px;
    }

    .tech-description {
        padding-bottom: 20px;
    }

    .search {
        padding-left: 30px;
        padding-right: 65px;
    }

    .search__item {
        width: 29%;
    }

    .search_button-down {
        right: 15px;
    }
}

@media (min-width: 992px) {
    .header-menu .ul-lvl-1 {
        list-style: none;

        display: flex;
    }

    .header-menu .a-lvl-1 {
        color: @font-revers-color;
        text-decoration: none;
        font-size: 11px;
        font-weight: 900;
        font-family: @base-font-family;

        display: block;

        margin-right: 75px;
        padding-right: 0;
        padding-left: 0;

        &:hover,
        &.active {
            text-decoration: underline;
        }
    }

    .all-content__body {
        display: flex;
        flex-direction: column;
    }

    .container {
        max-width: @content-width;
    }

    .content-block {
        padding: 27px 0;
    }

    .h1,
    h1 {
        font-size: 32px;
    }

    .h2,
    h2 {
        font-size: 26px;
    }

    .h3,
    h3 {
        font-size: 22px;
    }

    h4,
    .h4 {
        font-size: 20px;
    }

    .md-block {
        display: block;
    }

    .content {
        margin-top: 0;

        flex-grow: 1;
        display: flex;
        flex-direction: column;
    }

    .all-content__content {
        flex-grow: 1;
        margin: 0 auto;
        max-width: @content-width;

        &.h-mt30 {
            margin-top: 30px;
        }
    }

    .all-content__top,
    .slider-with-pager_top .bx-controls {
        margin: 0 auto;

        max-width: @content-width;
    }

    .logo-block {
        padding: 10px 15px 15px;
        .z-index(12);
        display: block;
        box-shadow: 0px 3px 15.36px 0.64px @shadow-color;

        text-align: center;

        .logo {
            width: auto;
            .z-index(10);
        }

        a {
            .z-index(10);
        }
    }

    .logo-subtitle {
        font-size: 1.1em;

        margin-bottom: 10px;
    }

    .a-tel {
        margin-right: 0;
    }

    .md-hidden {
        display: none !important;
    }

    .md-visible {
        display: block;
    }

    .pop-up_form {
        p {
            &.h3 {
                font-size: 23px;
            }
        }
    }

    .block_elem-list {
        .elem-list_item {
            padding: 20px 10px;
            margin-bottom: 28px;
        }

        .elem-list_item-img {
            margin-bottom: 23px;
        }

        .elem-list_item-name {
            margin-bottom: 10px;
        }

        .elem-list_item-price-block {
            .checkbox {
                margin-bottom: 17px;
            }
        }
    }

    .elem-list_listing .elem-list_item {
        padding: 0;
    }

    .elem-list_listing .elem-list_item-img {
        margin-bottom: 0;
    }

    ul.j-bxslider-video {
        li {
            text-align: center;

            iframe {
                max-width: 855px;
                height: 475px;
                margin-top: 15px;
            }
        }
    }

    .block-video {
        padding-bottom: 25px;

        .bx-wrapper {
            .bx-viewport {
                background: transparent;
            }
        }
    }

    .tech-description {
        p {
            font-size: 16px;
            line-height: 1.65;

            &.tech-desc_tit {
                margin-bottom: 10px;
            }
        }
    }

    .fake_table {
        margin-top: 0 !important;
    }

    .top-contacts,
    .header-menu,
    .container {
        margin: 0 auto;

        max-width: @content-width;
    }

    .top-contacts {
        flex-wrap: nowrap;

        padding: 22px 10px;
    }

    .header__item-search .top-contacts {
        padding-top: 15px;
        padding-bottom: 15px;
    }

    .header-menu {
        padding-left: @menu-width + 25px;
    }

    .header-menu .ul-lvl-1 {
        justify-content: flex-start;

        padding: 10px 0;
    }

    .top-search {
        width: auto;
        flex-grow: 1;
    }

    .top-contacts__item {
        padding-top: 0;
        margin-bottom: 0;

        flex-shrink: 0;
    }

    .top-contacts__item:not(:last-child) {
        margin-right: 20px;
    }

    .top-contacts__item-tel {
        border-right: 1px solid @secondary-color;

        padding-right: 20px;
    }

    .ul-col-2 .li-lvl-3:nth-child(odd) {
        border-right: 1px solid @secondary-color;

        width: 45%;
        margin-right: 3%;
    }

    .contect-with-img__desc,
    .contect-with-img__noimg {
        padding: 20px 30px;
    }

    .contect-with-img__desc-montazh {
        padding: 20px 13px 10px;
    }

    .contacts-title,
    .top-contacts__item-callme,
    .top-contacts__item-email {
        display: block;
    }

    .top-contacts__contacts-link {
        display: none;
    }

    .top-contacts__item-callme {
        flex-grow: 1;

        text-align: right;
    }

    .contect-with-img__img,
    .contect-with-img__noimg {
        max-width: 50%;
    }

    .contect-with-img__desc-montazh .ul li {
        padding-left: 0;
    }

    .contect-with-img__desc-montazh .ul li b {
        padding-left: 30px;
    }

    .good-media__item {
        margin-bottom: 30px;
    }
}

@media (min-width: 1100px) {
    .search__item .input-search {
        max-width: 100px;
    }
}

@media (min-width: 1200px) {
    .lg-block {
        display: block;
    }

    td.lg-block {
        display: table-cell;
    }

    table {
        font-size: 16px;
    }

    .table thead tr td,
    .table thead tr th {
        padding: 17px 15px;
    }

    .elem-list_listing .elem-list_item-img {
        width: 270px;
    }

    .contect-with-img__img,
    .contect-with-img__noimg {
        max-width: 100%;
    }

    .contect-with-img__img-obsluzh {
        margin-right: 74px;
    }

    .mr-b-before-ul {
        margin-bottom: 5px;
    }
}

.d-b {
    display: block;
}

.h-pt5 {
    padding-top: 5px;
}

.h-pb5 {
    padding-bottom: 5px !important;
}

.h-pt15 {
    padding-top: 15px;
}

.h-pb10 {
    padding-bottom: 10px;
}

.h-pb15 {
    padding-bottom: 15px;
}

.view {
    margin-top: 20px;
    padding-bottom: 0px;

    display: flex;
    align-items: center;


    button {
        width: 28px;
        height: 28px;
        margin-left: 10px;
        background-image: url('@{images-folder}view-sprite.png');
        background-color: transparent;
        border: none;
        cursor: pointer;
        font-size: 0;
    }

    .view__plitka {

        &.active,
        &:hover,
        &:focus {
            background-position: 0 -34px;
            outline: none;
        }
    }

    &__listing {
        background-position: -34px 0;

        &.active,
        &:hover,
        &:focus {
            background-position: -34px -34px;
            outline: none;
        }
    }

    &__table {
        background-position: -68px 0;

        &.active,
        &:hover,
        &:focus {
            background-position: -68px -34px;
            outline: none;
        }
    }
}

@media (min-width: 500px) {
    .view {
        margin-top: 0;
    }
}

.goods-table {
    table-layout: fixed;
    border-collapse: collapse;
    width: 100%;

    background-color: @base-color;

    margin-bottom: 20px;

    @media all and (min-width: 768px) {
        margin-bottom: 0;
    }

    th {
        background: @primary-color;
        border-left: 1px solid white;

        font-size: 12px;
        font-weight: normal;
        color: @font-revers-color;

        padding: 10px 2px;
    }

    th:first-child {
        border-left: none;
    }

    td {
        vertical-align: middle;
        border: 1px solid @secondary-color;
        text-align: center;
        padding: 15px 5px;

        background-color: @base-color;

        @media screen and (max-width: 768px) {
            padding: 15px 2px;
        }
    }

    .price-detail {
        text-align: center;
    }

    td:first-child p {
        padding-left: 0;
    }

    .elem-list_item-name {
        color: @body-text-color;
    }
}

.goods-table th,
.goods-table td {
    vertical-align: top;

    flex-shrink: 0;
    width: 50%;

    @media (min-width: 551px) {
        width: 20%;
    }

    @media (min-width: 800px) {
        width: 14.5%;
    }

    @media (min-width: 1200px) {
        width: 9.6%;
    }
}

.goods-table th:last-child,
.goods-table td:last-child {
    width: 64%;

    @media (min-width: 1100px) {
        width: 70%;
    }
}

.goods-table th:first-child,
.goods-table td:first-child {
    width: 36%;

    @media (min-width: 1100px) {
        width: 30%;
    }
}

.goods-table td {
    font-size: 12px;
    font-weight: bold;
    color: @font-color;
}

.goods-table td {
    border: none;
}

.goods-table_shadow {
    box-shadow: 0 4px 5px @shadow-color;
}

.info-above-catalog {
    @media (min-width: 500px) {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    @media (min-width: 768px) {
        font-size: 15px;
    }

    label {
        font-family: @special-font-family;
        text-transform: uppercase;
    }
}

form select {
    margin-bottom: 12px;
}

select {
    height: 36px;
    -webkit-appearance: none;
    background-image: url('@{images-folder}select-arrow.png');
    background-position: right 7px center;
    background-size: 15px;
    background-repeat: no-repeat;
    background-color: @base-color;

    line-height: @base-line-height;
    padding: 0 30px 0 15px;
    border: 1px solid @secondary-color;

    text-indent: .01px;
    text-overflow: "";
    appearance: none !important;
    font-size: 16px;

    &::-ms-expand {
        display: none;
    }
}

.catalog {
    display: flex;
    justify-content: center;
    padding: 5px 0;
    margin-bottom: 5px;

    border-bottom: 1px solid #000000;

    overflow-x: scroll;

    @media screen and (min-width: 768px) {
        /* padding: 15px 5px;
        margin-bottom: 30px;*/
        margin-bottom: 10px;
        flex-wrap: wrap;
        overflow-x: auto;
    }

    &--subcategories {
        padding: 0;
        margin-bottom: 0;

        border-bottom: none;

        @media all and (min-width: 768px) {
            padding: 5px;
        }

        .catalog__name {
            font-family: @title-font-family;
            font-weight: bold;

            a {
                color: #000000;
                word-break: break-word;
            }
        }
    }

    &__item {
        display: flex;
        flex-direction: column;
        flex-shrink: 0;
        width: 85px;
        margin-right: 5px;

        @media screen and (min-width: 768px) {
            width: 15%;
            min-width: 150px;
            margin-right: 10px;
        }
    }

    &__name {
        display: flex;
        height: 100%;
        justify-content: center;

        font-family: @special-font-family;
        font-size: 12.5px;
        text-align: center;
        word-break: break-all;

        &--small {
            font-size: 10.8px;
        }

        picture {
            flex-grow: 1;
            display: flex;
            align-items: center;
            justify-content: center;

            @media all and (min-width: 768px) {
                margin-bottom: 10px;
            }
        }

        @media all and (min-width: 768px) {
            width: 100%;

            font-size: 18px;

            &--small {
                font-size: 18px;
            }
        }
    }

    &__image-img {
        @media all and (min-width: 768px) {
            max-height: 150px;
        }
    }

    &__link {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        font-family: @title-font-family;
        font-weight: bold;
        color: #000000;

        @media all and (min-width: 768px) {
            padding: 20px;
        }

        &:hover {
            @media all and (min-width: 768px) {
                box-shadow: inset 0px 0px 4px 4px rgba(0, 100, 255, .1);
            }
        }
    }
}

.rating {
    display: flex;
    justify-content: center;

    text-align: center;

    &-star {
        color: @secondary-color;

        &.active {
            color: #fea300;
        }
    }

    &--reviews {
        font-size: 35px;

        @media screen and (max-width: 768px) {
            margin: 15px auto;
        }
    }
}

.elem-item {
    &__footer {
        text-align: center;
    }

    &__basket {
        margin-bottom: 15px;

        @media screen and (max-width: 768px) {
            margin-bottom: 4px;

            button {
                padding: 4px 6px;

                font-size: 10.8px;
            }
        }
    }

    &__rating {
        font-size: 30px;

        @media screen and (max-width: 768px) {
            font-size: 14px;
        }
    }
}

.form-mounting {
    margin-bottom: 30px;

    background-color: @primary-color;
    box-shadow: 0px 3px 6px 0px rgba(1, 6, 8, 0.4);

    @media all and (min-width: 768px) {
        margin-top: 30px;
    }

    &__container {
        position: relative;

        padding: 0 185px;

        @media screen and (max-width: 768px) {
            padding: 0 87px 0 74px;
        }

        &::before {
            top: -22px;
            left: 10%;

            display: block;
            width: 100px;
            height: 165px;

            background-image: url('@{images-folder}boiler.png');

            @media screen and (max-width: 768px) {
                top: -13px;
                left: 9px;
                width: 66px;
                height: 107px;
            }

            @media screen and (max-width: 400px) {
                width: 58px;
                height: 99px;
            }

        }

        &::after {
            top: -20px;
            right: 10%;

            display: block;
            width: 145px;
            height: 165px;

            background-image: url('@{images-folder}settings.png');

            @media screen and (max-width: 950px) {
                right: 7%;
            }

            @media screen and (max-width: 768px) {
                top: -13px;
                right: 3px;

                width: 95px;
                height: 106px;
            }

            @media screen and (max-width: 400px) {
                width: 84px;
                height: 96px;
            }
        }

        &::before,
        &::after {
            content: '';
            position: absolute;

            background-size: cover;
            background-repeat: no-repeat;
        }
    }

    &__body {
        position: relative;

        padding: 9px 0 5px;

        text-align: center;

        @media screen and (max-width: 768px) {
            .btn-default {
                padding: 4px 13px;

                font-size: 12.96px;
            }
        }

        @media screen and (max-width: 400px) {
            .btn-default {
                padding: 4px 7px;

                font-size: 9.96px;
            }
        }

        @media screen and (min-width: 600px) {
            padding-bottom: 12px;
        }
    }

    &__title {
        margin-bottom: 0;

        font-size: 25px;
        color: @font-revers-color;

        @media screen and (max-width: 768px) {
            font-size: 14px;
        }

        @media screen and (max-width: 400px) {
            font-size: 12px;
        }

        @media screen and (min-width: 600px) {
            margin-bottom: 10px;
        }

        @media screen and (min-width: 768px) {
            margin-bottom: 35px;
        }
    }

    &__subtitle {
        font-family: @special-font-family;
        font-size: 35px;
        line-height: 1;
        color: @warning-color;

        @media screen and (max-width: 768px) {
            margin-bottom: 3px;

            font-size: 19.8px;
        }

        @media screen and (max-width: 400px) {
            font-size: 16.8px;
        }
    }

    &__input {
        display: inline-block;
        width: 75%;
        max-width: 350px;
        padding: 4px 15px;
        margin-right: 10px;

        font-size: 16px;
        text-align: center;

        @media screen and (max-width: 768px) {
            margin-bottom: 3px;
            margin-top: 3px;
            padding: 4px 13px;

            font-size: 10.6px;
        }

        @media screen and (max-width: 400px) {
            padding: 4px 7px;

            font-size: 8.6px;
        }

        @media all and (min-width: 600px) {
            width: 60%;
        }
    }
}

.subscribe-form {
    padding: 5px 0;
    margin: 0 auto;
    max-width: 930px;

    color: @font-revers-color;

    background-color: @primary-color;

    @media all and (min-width: 768px) {
        padding: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        background-color: transparent;
        border: 1px solid @base-color;
    }

    &__container {
        padding-right: 50px;

        background-size: 45px;
        background-position: 100% 30%;
        background-image: url('@{images-folder}letter.png');
        background-repeat: no-repeat;

        @media all and (min-width: 768px) {
            padding-right: 0;
            width: 45%;

            background-position: 0 0;
        }
    }

    &__title {
        margin-bottom: 3px;

        font-family: @special-font-family;
        font-size: 12.96px;

        @media all and (min-width: 768px) {
            margin-bottom: 0;
            padding-bottom: 5px;
            padding-top: 5px;
            padding-left: 60px;

            font-size: 16px;
        }
    }

    &__body {
        display: flex;
        margin-bottom: 0;
    }

    &__input {
        flex-grow: 1;
        padding: 1px 15px 5px;
        margin-bottom: 0;
        max-width: 220px;

        font-size: 12.96px;
        text-align: left;

        @media all and (min-width: 768px) {
            padding: 5px;

            font-size: 14px;
        }
    }

    &__submit {
        padding: 4px 3px;
        margin-left: 5px;

        font-size: 7.2px;

        @media all and (min-width: 400px) {
            font-size: 10px;
        }

        @media all and (min-width: 768px) {
            margin-left: 10px;
            margin-top: 0;
            padding: 5px;
            max-width: 220px;
            flex-grow: 1;

            font-size: 16px;
        }
    }

    &__footer {
        margin-bottom: 0;

        font-size: 6.5px;

        font-family: @base-font-family;

        @media all and (min-width: 400px) {
            font-size: 10px;
        }

        @media all and (min-width: 768px) {
            width: 45%;

            font-size: 12px;
        }
    }
}

.collaps-item {

    &__title,
    &__footer {
        padding-top: 3px;
        padding-bottom: 3px;

        text-align: center;
        color: @font-revers-color;

        background-color: @primary-color;

        @media all and (min-width: 768px) {
            padding-top: 5px;
            padding-bottom: 5px;
        }
    }

    &__title {
        max-width: 1200px;
        width: 100%;
        margin-left: auto;
        margin-right: auto;

        box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .9);

        &--reviews {

            .collaps-item__name,
            .collaps-item__like,
            .collaps-item__sharing {
                width: 100px;

                font-family: @title-font-family;
                font-weight: bold;
            }

            .container {
                display: flex;
                justify-content: space-around;
                align-items: center;
            }
        }

        .container {
            position: relative;
        }

        &--col2 {
            .container {
                display: flex;
                justify-content: space-between;
                align-items: center;
            }

            .collaps-item__name {
                flex-shrink: 0;
                padding: 0;

                text-align: left;

                i {
                    margin-left: 10px;
                }
            }
        }

        &--white {
            @media all and (min-width: 768px) {
                background-color: @base-color;
                box-shadow: none;
            }

            h1,
            h2 {
                @media all and (min-width: 768px) {
                    color: #000000;
                }
            }
        }
    }

    &__name,
    &__like,
    &__sharing {
        font-family: @special-font-family;
        font-size: 14px;
        text-transform: uppercase;
        font-weight: bold;
    }

    &__sharing {
        text-align: right;
    }

    &__name {
        position: relative;

        padding: 5px 45px;

        @media screen and (max-width: 768px) {
            padding: 5px 25px;
        }

        @media screen and (max-width: 600px) {
            padding: 5px 17px;
        }

        @media screen and (max-width: 500px) {
            font-size: 12px;
        }

        @media screen and (max-width: 400px) {
            padding: 5px 13px;

            font-size: 10.8px;
        }

        a {
            color: @font-revers-color;
        }

        &--click {

            &::after {
                content: '';
                position: absolute;
                top: -15%;
                right: 30px;

                display: block;
                width: 20px;
                height: 20px;

                box-shadow: 3px 3px 0 0 @font-revers-color;

                transform: rotate(45deg);

                @media screen and (max-width: 768px) {
                    right: 20px;
                }

                @media screen and (max-width: 500px) {
                    right: 10px;

                    width: 15px;
                    height: 15px;
                }

                @media screen and (max-width: 360px) {
                    top: -3%;
                    right: 3px;

                    width: 13px;
                    height: 13px;
                }
            }
        }

        &--review {
            position: relative;

            height: 20px;

            &::before {
                content: '';
                position: absolute;
                left: -5px;
                top: 0;

                display: block;
                .sizes(150px, 57px);

                background-image: url('@{images-folder}review-pen.png');
                background-repeat: no-repeat;
                background-size: contain;

                @media all and (min-width: 500px) {
                    .sizes(175px, 95px);
                }

                @media all and (min-width: 600px) {
                    .sizes(277px, 107px);
                }
            }

            &::after {
                content: '';
                position: absolute;
                right: 0;
                top: -11px;

                display: block;
                .sizes(43px, 44px);

                background-image: url('@{images-folder}review-doc.png');
                background-repeat: no-repeat;
                background-size: contain;

                @media all and (min-width: 600px) {
                    .sizes(63px, 64px);
                }
            }
        }
    }

    &__name:not(.collaps-item__name--click) {
        @media screen and (max-width: 768px) {
            font-size: 14px;
        }

        @media screen and (max-width: 400px) {
            font-size: 12.96px;
        }
    }

    &__logo {
        display: inline-block;
        vertical-align: middle;
        margin-right: 5px;
        max-height: 35px;

        @media all and (min-width: 768px) {
            margin-right: 10px;
        }
    }

    &__icons-left {
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);
        left: 10px;

        @media all and (min-width: 768px) {
            left: 5%;
        }

        a {
            position: relative;
            z-index: 99;

            color: @font-revers-color;
        }
    }

    &__footer-body {
        display: flex;
        justify-content: space-around;
        align-items: center;

        @media screen and (max-width: 768px) {
            button {
                padding: 5px 20px 4px;

                font-size: 17px;
            }
        }

        @media screen and (max-width: 500px) {
            button {
                padding: 5px 10px 4px;

                font-size: 12px;
            }
        }
    }

    &__footer-title {
        margin-bottom: 0;

        font-family: @title-font-family;
        font-weight: 900;
        font-size: 18px;

        @media screen and (min-width: 600px) {
            font-size: 20px;
        }
    }

    &--revert {
        .collaps-item__title {
            padding: 10px 0;

            color: @body-text-color;

            background-color: @base-color;
            border-top: 1px solid @body-text-color;
            border-bottom: 1px solid @body-text-color;

            @media all and (min-width: 768px) {
                padding: 5px 0;
            }
        }
    }
}

.delivery {
    @border-color: #e5e5e5;
    @local-link-color: #418fd7;

    &__title {
        margin-bottom: 20px;

        font-size: 15px;
        text-align: center;

        @media screen and (min-width: 600px) {
            font-size: 21px;
        }
    }

    &__body {
        display: flex;
        padding-bottom: 10px;
        margin-bottom: 15px;

        border-bottom: 1px solid @border-color;
    }

    &__list {
        width: 37%;
        min-width: 130px;
        padding-right: 15px;

        border-right: 1px solid @border-color;
    }

    &-list {
        margin-bottom: 15px;

        list-style: none;
        font-size: 14px;

        &__link {
            text-decoration: underline;
            color: @local-link-color;

            &:hover {
                text-decoration: none;
            }
        }

        @media all and (min-width: 600px) {
            font-size: 15px;
        }
    }

    &__link {
        font-size: 12px;
        text-decoration: underline;
        color: @local-link-color;

        &:hover {
            text-decoration: none;
        }

        @media all and (min-width: 600px) {
            font-size: 14px;
        }
    }

    &__search {
        position: relative;

        flex-grow: 1;
        padding-left: 10px;

        &-block {
            display: flex;
        }

        &::after {
            content: '';
            position: absolute;
            bottom: 0;
            right: 0;

            display: block;
            .sizes(60px, 90px);

            background-image: url('@{images-folder}search-eye.jpg');
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;

            @media all and (min-width: 360px) {
                .sizes(75px, 110px);
            }
        }

        @media all and (min-width: 600px) {
            padding-left: 25px;
        }

        @media all and (min-width: 768px) {
            padding-left: 35px;
        }
    }

    &-search {
        display: flex;
        margin-bottom: 3px;

        &__input {
            flex-grow: 1;
            padding: 5px;

            font-size: 14px;

            border-radius: 0;
            border: 2px solid #b8b8b8;
            border-right: none;

            @media all and (min-width: 600px) {
                padding: 10px;

                font-size: 15px;
            }

            @media all and (min-width: 768px) {
                padding: 10px 15px;
            }
        }

        &__submit {
            width: 80px;
            display: flex;
            justify-content: center;
            align-items: center;

            color: #212121;

            border-radius: 0;
            border: none;
            background-color: #e9ae30;

            cursor: pointer;
        }
    }

        &-result {
            position: absolute;
            top: 32px;
            left: 10px;
            .z-index(10);

            padding: 10px 15px;
            box-sizing: border-box;

            font-size: 12px;

            background-color: rgba(255,255,255,0.7);

            @media screen and (min-width: 600px) {
                top: 43px;
                left: 25px;

                font-size: 15px;
            }

            @media screen and (min-width: 768px) {
                left: 35px;
            }

            > div {
                text-decoration: underline;
                color: @body-text-color;

                &:hover {
                    text-decoration: none;
                }

                a:hover {
                    text-decoration: none;
                }
            }
        }

    &__map {
        width: ~'calc(100% - 10px)' !important;
        display: flex;
        justify-content: center;
        box-sizing: content-box;
        margin-bottom: 20px;

        border: 4px solid @primary-color;

        @media all and (min-width: 768px) {
            height: 400px !important;
        }
    }
}

.ymaps-map,
.delivery__map {
    border-radius: 15px;
}

.form-group {
    .rating {
        margin: 0;
        justify-content: flex-start;
        width: 65%;

        @media screen and (min-width: 1000px) {
            width: 800px;
        }

        .icons--star {
            .sizes(30px, 30px);
            cursor: pointer;
        }
    }
}

.collaps-item__title--reviews .container {
    display: grid;
    grid-template-columns: 1fr minmax(max-content, 60%) 1fr;
    justify-items: center;
    gap: 10px;

    button {
        width: auto;
        white-space: nowrap;
    }
}

.review-form {
    &__title {
        margin-bottom: 25px;

        font-family: @title-font-family;
        font-size: 12px;
        font-weight: bold;

        @media screen and (min-width: 500px) {
            font-size: 14px;
        }

        @media screen and (min-width: 600px) {
            font-size: 16px;
        }

        @media screen and (min-width: 768px) {
            font-size: 18px;
        }

        & p.h-mb0 {
            padding: 5px 0 0 110px;

            @media all and (min-width: 380px) {
                padding-bottom: 0;
                padding-left: 135px;
            }

            @media all and (min-width: 500px) {
                padding-left: 180px;
            }

            @media all and (min-width: 600px) {
                padding: 15px 0 10px 240px;
            }

            @media all and (min-width: 768px) {
                padding: 15px 0 0;

                text-align: center;
            }
        }
    }

    &__subtitle {
        display: flex;
        justify-content: center;

        font-size: 18px;
        text-align: center;

        /*@media screen and (min-width: 380px) {
            padding-left: 135px;

            text-align: left;
        }*/

        @media screen and (min-width: 500px) {
            /*padding-left: 180px;*/

            font-size: 20px;
        }

        @media screen and (min-width: 600px) {
            /*padding-left: 240px;*/

            font-size: 30px;
        }

        @media screen and (min-width: 768px) {
            /*padding-left: 0;*/

            font-size: 50px;
            /*text-align: center;*/
        }
    }

    &__body {
        display: none;
    }
}

.experience {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;

    @media screen and (max-width: 999px) {
        width: 65%;
    }

    @media screen and (min-width: 1000px) {
        width: 800px;
    }

    &__item {
        text-align: center;
        vertical-align: center;
        text-transform: uppercase;
        font-family: @base-font-family;
        color: @primary-color;

        border: 1px solid #A1A1A1;
        border-radius: 4px;

        @media screen and (max-width: 999px) {
            width: 49%;
            height: 29px;
        }

        @media screen and (min-width: 1000px) {
            width: 24%;
            height: 32px;
        }

        .radio-btn-group__label {
            padding: 5px;

            @media screen and (max-width: 999px) {
                font-size: 11px;
                line-height: 13px;
            }

            @media screen and (min-width: 1000px) {
                font-size: 14px;
                line-height: 16px;
            }
        }
    }
}

@supports (display: grid) {
    @media screen and (max-width: 999px) {
        .experience {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-template-rows: 1fr 1fr;
            grid-gap: 6px;

            &__item {
                width: 100%;
            }
        }
    }

    @media screen and (min-width: 1000px) {
        .experience {
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            grid-gap: 10px;

            &__item {
                width: 100%;
            }
        }
    }
}

.radio-btn-group {
    display: inline-block;
    vertical-align: middle;
    margin-left: 5px;

    font-size: 0;

    &__item {
        position: relative;

        display: inline-block;
        width: 50%;

        font-family: @base-font-family;
        font-size: 14px;
        font-weight: bold;
        text-transform: uppercase;
        color: #646464;

        border: 2px solid #646464;

        &:not(:first-child) {
            border-left-width: 1px;
        }

        &:not(:last-child) {
            border-right-width: 1px;
        }

        @border-radius: 4px;

        &:first-child {
            border-top-left-radius: @border-radius;
            border-bottom-left-radius: @border-radius;
        }

        &:last-child {
            border-top-right-radius: @border-radius;
            border-bottom-right-radius: @border-radius;
            color: white;
            background-color: #646464;
        }
    }

    &__label,
    label.radio-btn-group__label {
        display: flex;
        width: 100%;
        height: 100%;
        padding: 3px 7px;
        justify-content: center;
        align-items: center;

        text-align: center;

        cursor: pointer;

        &:hover {
            color: @font-revers-color;

            background-color: @primary-color;
        }

        &::before,
        &::after {
            display: none;
        }

        @media screen and (min-width: 410px) {
            padding: 3px 10px;
        }

        @media screen and (min-width: 500px) {
            padding: 5px 15px;
        }

        @media screen and (min-width: 768px) {
            font-size: 13px;
        }

        @media screen and (max-width: 600px) {
            min-height: 0;

            font-size: 9px;
        }
    }

    &__input {
        display: none;

        &:checked + label {
            color: @font-revers-color;

            background-color: @primary-color;
        }
    }

    &--all-width {
        display: flex;
        width: 100%;
        max-width: 800px;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 20px;

        .radio-btn-group__item {
            flex-grow: 1;

        }
    }
}

.form-group {
    display: flex;
    align-items: flex-start;

    margin-bottom: 20px;

    font-size: 18px;
    line-height: 1em;

    &__label {

        flex-shrink: 0;
        flex-grow: 1;

        font-family: @title-font-family;
        font-weight: 900;

        @media all and (max-width: 767px) {
            width: 35%;
            margin-right: 5px;
        }

        @media all and (min-width: 768px) {
            width: 135px;
            margin-right: 20px;
        }
    }

    &__input {
        width: 65%;
        padding: 5px 10px;

        font-size: 12px;

        border: 1px solid rgb(161, 161, 161);
        border-radius: 5px;

        @media all and (min-width: 600px) {
            height: 5.5em;

            font-size: 14px;
        }

        @media all and (min-width: 768px) {
            padding: 10px 15px;
        }

        @media all and (min-width: 1000px) {
            width: 800px;
        }
    }
}

.reviews {
    padding-left: 30px;
    padding-right: 20px;

    &__item {
        margin-bottom: 50px;
    }
}

.review {
    position: relative;

    font-size: 13px;

    @media screen and (min-width: 500px) {
        font-size: 15px;
    }

    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 0px;
    }

    &__name {
        margin-bottom: 0;

        font-weight: bold;
    }

    &__row {
        margin-bottom: 16px;

        &:first-of-type {
            margin-bottom: 0;
        }
    }

    &__date {
        @media screen and (max-width: 460px) {
            margin-top: 10px;
        }
    }
}

.good-media {
    h1, .h1 {
        font-family: @title-font-family;
        font-weight: bold;
        font-size: 30px;

        @media all and (min-width: 400px) {
            font-size: 33px;
        }

        @media all and (min-width: 768px) {
            font-size: 35px;
        }
    }

    .icons--compare,
    .icons--added {
        margin-right: 5px;
        margin-bottom: 1px;
    }

    .good-media-footer__item {
        justify-content: space-around;
    }

    .good-chars {
        display: none;

        @media all and (min-width: 768px) {
            display: block;
        }
    }

    .bx-wrapper {
        @media all and (min-width: 768px) {
            margin: 0;
        }

        .bx-controls-direction .bx-prev,
        .bx-controls-direction .bx-next {
            @media all and (min-width: 768px) {
                left: 50%;
            }
        }

        .bx-controls-direction .bx-prev {
            @media all and (min-width: 768px) {
                top: 0;

                transform: translate(-50%, -28px) rotate(90deg);
            }
        }

        .bx-controls-direction .bx-next {
            @media all and (min-width: 768px) {
                bottom: 0;
                top: auto;

                transform: translate(-50%, 23px) rotate(-90deg);
            }
        }
    }

    /*.elem-list_item-img img {
        @media all and (min-width: 768px) {
            max-width: 200px;
        }
    }*/
}

.delivery-ul {
    width: 100%;
    margin-bottom: 20px;

    font-family: @title-font-family;
    font-weight: bold;
    font-size: 14px;
    color: #5a5a5a;

    @media all and (min-width: 600px) {
        font-size: 16px;
    }

    @media all and (min-width: 768px) {
        width: auto;
        margin-bottom: 0;
    }

    &__item {
        display: flex;
        align-items: center;
        margin-bottom: 10px;

        @media all and (min-width: 768px) {
            margin-bottom: 0;
        }
    }
}

.media-imgs-more__item {
    width: 26px !important;
    margin-right: 5px !important;

    text-align: center;

    @media all and (min-width: 340px) {
        width: 30px !important;
    }

    @media all and (min-width: 370px) {
        width: 35px !important;
    }

    @media all and (min-width: 400px) {
        width: 50px !important;
    }

    @media all and (min-width: 600px) {
        width: 55px !important;
    }

    @media all and (min-width: 768px) {
        width: auto !important;
        display: block;
    }
}

.more-images-wrapper {
    @media all and (min-width: 768px) {
        width: 70px;
    }
}

.media-imgs .bx-viewport,
.more-images-wrapper {
    @media all and (max-width: 767px) {
        height: 55px !important;
    }

    @media all and (max-width: 599px) {
        height: 50px !important;
    }

    @media all and (max-width: 399px) {
        height: 35px !important;
    }

    @media all and (max-width: 369px) {
        height: 30px !important;
    }
}

.good-media-footer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 20px;

    &__item {
        &--compare {
            display: flex;
            flex-direction: column;
            align-items: center;

            .btn-active {
                @media all and (max-width: 768px) {
                    padding: 4px 10px;

                    font-size: 14px;
                }

                @media all and (max-width: 400px) {
                    padding: 4px 7px;
                }
            }
        }
    }

    @media all and (max-width: 450px) {
        margin-top: 12px;
    }
}

.good-code {
    margin-bottom: 20px;

    font-family: @title-font-family;
    font-weight: bold;
    font-size: 18px;
    color: #8a8a8a;

    @media all and (min-width: 768px) {
        margin-bottom: 5px;

        font-size: 15px;
    }
}

.good-producer {
    &__flag {
        display: inline-block;
        vertical-align: middle;
        max-width: 30px;

        @media all and (min-width: 400px) {
            max-width: 45px;
        }

        @media all and (min-width: 768px) {
            max-width: 50px;
        }
    }

    &__link {
        font-family: @special-font-family;
        font-size: 17px;
        text-transform: uppercase;
        text-decoration: underline;
        color: @primary-color;

        &:hover {
            text-decoration: none;
        }
    }
}

.good-media {
    display: flex;
    padding: 0;
    max-width: 900px;
    margin-left: auto;
    margin-right: auto;
}

.mobile-header__nav {
    width: 23%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media all and (min-width: 768px) {
        width: 40%;
    }
}

.elem-item {
    .good-producer {
        margin-bottom: 0;
        padding-left: 0;

        @media all and (min-width: 768px) {
            padding-left: 0;
            margin-bottom: 5px;
        }

        &__flag {
            max-width: 20px;
            margin-right: 5px;

            @media all and (min-width: 768px) {
                max-width: 45px;
            }
        }

        &__link {
            font-family: @special-font-family;
            font-size: 14px;
            text-decoration: none;
            text-transform: none;
            color: @body-text-color;

            &:hover {
                text-decoration: underline;
            }

            @media all and (min-width: 768px) {
                font-family: @base-font-family;
                font-weight: bold;
                font-size: 18px;
            }
        }
    }
}

.description-boilers {
    position: relative;

    padding: 0 130px;
    overflow-x: hidden;

    @media all and (min-width: 768px) {
        padding: 20px 15%;
    }

    &::before {
        content: url(/images/boiler-1.jpg);
        position: absolute;
        .z-index(-2);
        bottom: 0;
        left: -140px;

        @media all and (min-width: 768px) {
            bottom: -20px;
        }
    }

    &::after {
        content: url(/images/boiler-2.jpg);
        position: absolute;
        .z-index(-2);
        top: 0;
        right: -80px;

        @media all and (min-width: 768px) {
            top: 15px;
        }
    }

    @media screen and (max-width: 650px) {
        padding: 0 15%;

        &::before {
            left: -208px;
        }

        &::after {
            right: -146px;
        }

        & p {
            font-size: 12px;
        }
    }

    @media screen and (max-width: 400px) {
        & p {
            font-size: 11px;
        }
    }
}
.description-subcat{
    position: relative;

    padding: 0 130px;

    @media all and (min-width: 768px) {
        padding: 20px 15%;
    }

    @media screen and (max-width: 650px) {
        padding: 0 15%;

        & p {
            font-size: 12px;
        }
    }

    @media screen and (max-width: 400px) {
        & p {
            font-size: 11px;
        }
    }
}
.description-radiators {
    position: relative;

    padding: 0 130px;
    overflow-x: hidden;

    @media all and (min-width: 768px) {
        padding: 20px 15%;
    }

    &::before {
        content: url(/images/for_main_rad2.jpg);
        position: absolute;
        .z-index(-2);
        bottom: 0;
        left: -100px;

        @media all and (min-width: 768px) {
            bottom: -20px;
        }
    }

    &::after {
        content: url(/images/for_main_rad1.jpg);
        position: absolute;
        .z-index(-2);
        top: 0;
        right: -60px;

        @media all and (min-width: 768px) {
            top: 15px;
        }
    }

    @media screen and (max-width: 650px) {
        padding: 0 15%;

        &::before {
            left: -208px;
        }

        &::after {
            right: -146px;
        }

        & p {
            font-size: 12px;
        }
    }

    @media screen and (max-width: 400px) {
        & p {
            font-size: 11px;
        }
    }
}

.description {
    text-transform: uppercase;
    font-size: 11px;

    @media all and (min-width: 400px) {
        font-size: 12px;
    }

    @media all and (min-width: 650px) {
        font-size: 14px;
    }
}

.social-links {
    &__image {
        @media screen and (max-width: 768px) {
            max-width: 27px;
        }
    }
}

.bottom-menu {
    position: fixed;
    width: 100%;
    bottom: 0;
    .z-index(500);

    padding: 0;

    font-size: 9px;
    color: @font-revers-color;

    background-color: @primary-color;

    @media screen and (min-width: 400px) {
        padding: 10px 0 4px;

        font-size: 16px;
    }

    @media all and (min-width: 768px) {
        display: none;
    }

    &__body {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: flex-end;
    }

    &__link {
        position: relative;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 7px;
        height: 100%;
        width: ~'calc((100% - 80px) / 4)';

        color: @font-revers-color;
        font-size: 10px;

        border: none;
        background: transparent;

        &:hover {
            text-decoration: none;

            background-color: lighten(@primary-color, 10%);
        }

        &::before {
            content: '';

            display: block;
            margin: 0;

            background-size: contain;
            background-repeat: no-repeat;
        }

        &--home::before {
            .sizes(21px, 21px);

            background-image: url('@{icons-folder}home.svg');
        }

        &--discount > span {
            position: relative;
        }

        &--discount::before {
            .sizes(15px, 22px);

            background-image: url('@{icons-folder}fire-flame.svg');
        }

        &--call::before {
            .sizes(21px, 22px);

            background-image: url('@{icons-folder}phone.svg');
        }

        &--email::before {
            .sizes(24px, 17px);

            background-image: url('@{icons-folder}e-mail.svg');
        }

        /*&--share::before {
            .sizes(22px, 19px);

            background-image: url('@{icons-folder}sharing_light.svg');

            transform: translate(0, -2px) rotate(27deg);
        }*/

        &--share {
            width: 80px;

            &::before {
                display: none;
            }
        }

        &--home::before,
        &--email::before {
            transform: translate(0, -2px);
        }
    }

    &__count {
        position: absolute;
        top: -25px;
        left: 13px;

        padding: 0 3px;

        font-size: 11px;
        color: @body-text-color;

        background-color: @base-color;
        border: 1px solid #5a5a5b;
        border-radius: 20px;
    }
}

.search-data {
    display: flex;
    margin-bottom: 20px;

    @media screen and (max-width: 768px) {
        flex-wrap: wrap;
    }

    &__title {
        flex-shrink: 0;
        margin-right: 15px;

        font-family: @special-font-family;
        text-transform: uppercase;

        @media all and (max-width: 400px) {
            font-size: 8.64px;
        }
    }

    &__body {
        display: flex;
        flex-grow: 1;
        justify-content: flex-end;
        flex-wrap: wrap;
    }

    &__item {
        padding: 2px 7px;
        margin: 0px 3px 3px;

        color: @font-revers-color;

        background-color: @font-color;
        border-radius: 5px;

        @media all and (max-width: 400px) {
            padding: 3px;

            font-size: 6px;
            text-transform: uppercase;
        }
    }

    &__close {
        position: relative;

        display: inline-block;
        vertical-align: text-bottom;
        width: 15px;
        height: 15px;
        margin-left: 5px;

        @media all and (max-width: 400px) {
            .sizes(6px, 6px);
        }

        &::before,
        &::after {
            content: '';
            position: absolute;
            left: 0;
            bottom: 6px;

            display: block;

            width: 17px;
            height: 2px;

            background-color: @font-revers-color;

            @media all and (max-width: 400px) {
                bottom: 3px;

                width: 6px;
            }
        }

        &::after {
            transform: rotate(45deg);
        }

        &::before {
            transform: rotate(-45deg);
        }
    }
}

.goods-table {
    .good-code {
        font-size: 11.8px;
        text-align: left;

        @media all and (min-width: 400px) {
            font-size: 15px;
        }
    }

    .good-producer {
        font-family: @title-font-family;
        text-align: left;
        text-decoration: none;
        color: @body-text-color;

        @media all and (min-width: 768px) {
            width: 100%;
            padding-right: 65px;
            padding-top: 20px;
            display: flex;
            align-items: center;
        }

        a {
            text-decoration: none;
            color: @body-text-color;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    .elem-list_item-name {
        font-family: @title-font-family;
        font-weight: normal;
        font-size: 19.8px;
        text-align: left;
        color: @body-text-color;

        @media all and (min-width: 768px) {
            min-height: 2em;

            font-size: 22px;
        }
    }

    .good-chars {
        min-height: 60px;
        margin-bottom: 25px;

        text-transform: uppercase;

        @media all and (max-width: 768px) {
            min-height: 20px;
        }

        @media all and (max-width: 450px) {
            margin-bottom: 15px;
        }
    }

    .good-chars__item,
    .good-chars__elem {
        font-weight: normal;
        font-size: 10.8px;
        font-family: 'Tahoma', sans-serif;

        @media all and (min-width: 768px) {
            font-size: 14px;
        }

        @media screen and (max-width: 380px) {
            text-transform: none;
        }
    }

    .good-chars__item {
        position: relative;
        bottom: -1px;
    }

    .rating {
        font-size: 25px;

        @media screen and (max-width: 768px) {
            font-size: 15px;
        }
    }

    &__item {
        &--footer {
            td {
                padding: 0;
            }
        }
    }
}

.content-block.h-pb35:last-of-type {
    padding-bottom: 15px;
}

.delivery-ul__item::before {
    content: '';
    position: absolute;
    left: 0;

    display: block;
    .sizes(35px, 20px);

    background-size: contain;
    background-repeat: no-repeat;
}

.delivery-ul__item {
    position: relative;

    padding-left: 40px;

    &--fast::before,
    &--tell-us::before {
        transform: scale(-1, 1);
    }

    &--fast::before {
        background-image: url('@{icons-folder}car-green.svg');
    }

    &--tell-us::before {
        background-image: url('@{icons-folder}car-yellow.svg');
    }

    &--no::before {
        left: 6px;

        .sizes(24px, 23px);

        background-image: url('@{images-folder}sprite_icons.png');
        background-position: -111px -81px;
        background-size: auto;
    }
}

.collaps-item__like,
.collaps-item__name,
.flex__row,
.flex__row-full,
.good-media-footer__item,
.good-media-footer__item--compare,
.review__mark {
    display: flex;
    flex-direction: row;
}

.flex__column,
.flex__column-full,
.good-media__item.media-imgs {
    display: flex;
    flex-direction: column;
}

.collaps-item__name {
    justify-content: center;
    padding-right: 32px;
}

.flex__row {
    align-items: center;

    @media screen and (max-width: 560px) {
        flex-direction: column;
    }

    & > img {
       flex-shrink: 0;
    }
}

.flex-grow-1 {
    flex-grow: 1;
}

.good-media-footer__item.flex__column-full {
    align-items: flex-start;
    width: 40%;

    &:last-of-type {
        width: 58%;
        justify-content: space-between;
    }
}

.flex__row-full {
    align-items: flex-end;
    justify-content: space-between;
}

.good-media__item.media-imgs {
    justify-content: space-between;
    width: 37%;
    max-width: 194px;

    @media all and (min-width: 768px) {
        width: 45%;
        max-width: none;
        order: -1;
        flex-direction: row-reverse;
        align-items: center;
        padding-bottom: 5px;
    }

    .elem-list_item-img {
        @media all and (min-width: 768px) {
            margin-bottom: 0;
        }
    }

    /* границы у картинок?
    img {
        border: 1px solid rgba(0, 0, 0, 0.2);
    }*/
}

.good-media__item.goods-desc_block {
    width: 60%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media all and (min-width: 768px) {
        max-width: 400px;
    }
}

.align {
    &-center {
        align-items: center;
    }

    &-start {
        align-items: flex-start;
    }

    &-stretch {
        align-items: stretch;
    }

    &-self-center {
        align-self: center;
    }
}

.justify {
    &-between {
        justify-content: space-between;
    }

    &-around {
        justify-content: space-around !important;
    }

    &-start {
        justify-content: flex-start;
    }

    &-center {
        justify-content: center !important;
    }
}

.good-media-footer__item,
.good-media-footer__item--compare {
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.collaps-item__like,
.collaps-item__name {
    align-items: center;
}

.goods-table__header {
    position: relative;

    border-bottom: none;
}

.discount__wrapper {
    display: inline-block;
    .sizes(55px, 46px);

    background-image: url('@{icons-folder}sale.svg');
    background-size: contain;
    background-repeat: no-repeat;

    &--left {
        position: absolute;
    }

    @media all and (min-width: 768px) {
        position: absolute;
        right: 20px;
        top: 20px;

        .sizes(66px, 66px);
    }
}

.discount-price {
    display: inline-block;

    font-size: 13px;
    color: @base-color;

    transform: rotate(-45deg) translate(-14px, 7px);

    @media all and (min-width: 768px) {
        font-size: 15px;

        transform: rotate(-45deg) translate(-20px, 16px);
    }
}

.f-uppercase.f-underline {
    align-self: flex-end;
}

.collaps-item__like span:last-of-type {
    margin-left: 5px;
}

.collaps-item__like:hover {
    span:last-of-type {
        color: @attention-color;
    }

    .icons--like,
    .icons--like-light {
        background-image: url('@{icons-folder}like_active.svg');
    }
}

.h-h50 {
    height: 50px;
}

.main-image-link {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}
.elem-list_item-img {
    position: relative;

    & .discount__wrapper--left {
        left: 3px;
        top: 3px;

        @media all and (min-width: 768px) {
            .sizes(86px, 86px);
        }

        .discount-price {
            @media all and (min-width: 768px) {
                font-size: 20px;

                transform: rotate(-45deg) translate(-24px, 18px);
            }
        }
    }
}

.discount + .price-good {
    color: #f90219;
}

.review__mark {
    position: relative;

    &--pen {
        position: absolute;
        top: 0;
        left: -35px;

        .sizes(60px, 50px);

        background-image: url('@{images-folder}sprite_icons.png');
        background-repeat: no-repeat;
        background-position: -121px -131px;
    }

    &--number {
        margin-left: 35px;

        @media all and (min-width: 768px) {
            width: 50px;
            height: 50px;
        }

        text {
            font-size: 18px;

            @media all and (min-width: 768px) {
                font-size: 22px;
            }
        }
    }
}

.reviews__item.review:last-of-type {
    margin-bottom: 0;
}

.wrapper {
    @media all and (max-width: 768px) {
        padding-left: 15px;
        padding-right: 15px;
    }
}

.doc-list__wrapper {
    @media all and (max-width: 768px) {
        padding-left: 7px;
        padding-right: 7px;
    }
}

.fa-search {
    font-size: 16px;
}

.collaps-item__footer-body .rating--reviews {
    margin: 10px 0;
}

.collaps-item__footer-body .rating--reviews .icons--star {
    .sizes(16px, 16px);

    background-image: url('@{icons-folder}star_active.svg');
    background-size: contain;

    &:first-of-type:hover {

        & + .icons--star + .icons--star + .icons--star + .icons--star,
        & + .icons--star + .icons--star + .icons--star,
        & + .icons--star + .icons--star,
        & + .icons--star {
            background-image: url('@{icons-folder}star.svg');
        }
    }

    &:nth-of-type(2):hover {

        & + .icons--star + .icons--star + .icons--star,
        & + .icons--star + .icons--star,
        & + .icons--star {
            background-image: url('@{icons-folder}star.svg');
        }
    }

    &:nth-of-type(3):hover {

        & + .icons--star + .icons--star,
        & + .icons--star {
            background-image: url('@{icons-folder}star.svg');
        }
    }

    &:nth-of-type(4):hover {
        & + .icons--star {
            background-image: url('@{icons-folder}star.svg');
        }
    }
}

.collaps-item__name--click.collapse-open::after {
    top: 50%;

    transform: rotate(-135deg);
}

.f-base {
    font-family: @base-font-family;
}

.f-base.f-16 {
    position: relative;
    bottom: 0.1em;
    right: 5px;

    font-size: 12.96px;

    @media all and (min-width: 400px) {
        font-size: 16px;
    }
}

.collaps-list {
    margin-bottom: 5px;

    h2:not(:first-of-type) {
        margin-top: 1em;
    }
}

.good-media-footer__item .checkbox {
    position: absolute;
    right: 5%;

    align-self: baseline;

    @media all and (min-width: 400px) {
        right: 8%;
    }

    @media all and (min-width: 768px) {
        margin-bottom: 10px;
    }
}

.pos-relative {
    position: relative;
}

.s-set-compare:hover {
    cursor: pointer;

    & .f-underline {
        text-decoration: none;
    }
}

.s-set-compare a {
    color: #333333;
}

.good-media-footer__item--compare .checkbox {
    position: static;

    width: 100%;
}

.good-media-footer__item--compare .checkbox a,
.good-media-footer__item--compare .checkbox span:first-of-type {
    display: flex;
    justify-content: center;
    align-items: center;
}

.good-media-footer__item--compare .checkbox a,
.good-media-footer__item--compare .checkbox span:first-of-type {
    @media all and (min-width: 768px) {
        justify-content: flex-start;
    }
}

input::placeholder,
textarea::placeholder {
    color: #cccccc;
}

.category_img img {
    height: 86px;
    object-fit: contain;

    @media all and (min-width: 768px) {
        height: 150px;
    }
}

.category_img {
    margin-bottom: 5px;

    text-align: center;

    @media all and (min-width: 768px) {
        margin-bottom: 10px;
    }
}

.goods-table .collaps-item--revert .collaps-item__title {
    box-shadow: none;
}

.goods-table .collaps-item--revert .collaps-item__title .collaps-item__name {
    padding-left: 0;
    padding-right: 0;
}

.icons {
    display: inline-block;
    .sizes(27px, 34px);

    background-size: contain;
    background-repeat: no-repeat;

    &--like,
    &--like-light {
        margin-right: 5px;
        .sizes(23px, 23px);

        background-image: url('@{icons-folder}like.svg');

        @media all and (min-width: 400px) {
            .sizes(31px, 35px);
        }

    }

    &--comment,
    &--comment-light {
        margin-right: 9px;
        .sizes(20px, 17px);

        background-image: url('@{icons-folder}comment.svg');

        @media all and (min-width: 400px) {
            .sizes(25px, 22px);
        }

    }

    &--like-light {
        background-image: url('@{icons-folder}like_light.svg');
    }

    &--comment-light {
        background-image: url('@{icons-folder}comment_light.svg');
    }

    &--menu {
        .sizes(25px, 23px);

        background-image: url('@{icons-folder}burger.svg');
    }

    &--search {
        .sizes(24px, 19px);

        background-image: url('@{icons-folder}search.svg') !important;
    }

    &--compare {
        .sizes(20px, 20px);

        background-image: url('@{icons-folder}compare-2.svg');
    }

    &--shopping-cart {
        .sizes(25px, 23px);

        background-image: url('@{icons-folder}shopping-cart.svg');
    }

    &--search,
    &--compare,
    &--shopping-cart,
    &--menu {
        will-change: auto;
    }

    &--cart {
        .sizes(12px, 12px);

        background-image: url('@{icons-folder}shopping-cart-mini.svg');

        transform: translate(0, 2px);

        @media all and (min-width: 768px) {
            .sizes(15px, 15px);
        }
    }

    &--close {
        .sizes(30px, 30px);

        background-image: url('@{icons-folder}close-bold.svg');

        transition: 0.2s;

        &:hover {
            transform: scaleX(-1);
        }
    }
}

.menu-btn_close .icons--menu {
    background-image: url('@{icons-folder}close.svg');
}

.menu-btn.menu-btn_close:hover .icons--menu {
    background-image: url('@{icons-folder}close-light-blue.svg');
}

.icons-svg.icons {

    &--sharing,
    &--sharing-light {
        .sizes(22px, 20px);

        transform: rotate(27deg) translate(0, -2px);

        @media all and (min-width: 768px) {
            .sizes(27px, 25px);

            transform: rotate(27deg) translate(0, -4px);
        }
    }

    &--added,
    &--compare {
        .sizes(15px, 14px);

        @media all and (min-width: 768px) {
            .sizes(20px, 18px);
        }
    }

    &--added {
        margin-right: 3px;
    }

    &--back {
        .sizes(27px, 34px);

        transform: scale(-1, 1);

        @media all and (min-width: 768px) {
            display: none;
        }
    }

    &--info {
        .sizes(23px, 28px);
    }

    &--filter {
        .sizes(27px, 27px);
        margin-left: 6px;
    }

    &--star,
    &--star_active {
        .sizes(17px, 17px);
    }

    // &--sort-asc,
    // &--sort-desc {
    //     /*.sizes(17px, 17px);
    //     background-image: url('@{icons-folder}down.svg');*/
    // }
}

.goods-table,
.good-media-footer {
    .checkbox {
        font-size: 14px;
        font-family: @title-font-family;

        @media all and (max-width: 400px) {
            font-size: 10.8px;
        }
    }

    .good-media-footer__item--compare .btn-active {
        padding: 4px 10px;

        font-size: 15px;

        @media all and (max-width: 400px) {
            padding: 4px 5px;

            font-size: 10.8px;
        }
    }

    .f-uppercase.f-underline.f-light-base {
        @media all and (max-width: 400px) {
            font-size: 6.50px;
        }
    }

    .collaps-item--revert .collaps-item__title {
        @media all and (max-width: 400px) {
            padding-top: 3px;
            padding-bottom: 3px;
        }
    }

    .discount__wrapper {
        transform: translateY(-9px);

        @media all and (max-width: 400px) {
            .sizes(45px, 45px);
        }
    }

    .discount-price {
        @media all and (max-width: 400px) {
            transform: rotate(-45deg) translate(-12px, 9px);
        }
    }
}

.flex__row-full.filter {
    align-items: center;

    @media all and (max-width: 400px) {
        font-size: 14px;
    }

    /*@media all and (min-width: 1100px) {
        pointer-events: none;
        cursor: default;
    }*/
}

.flex__row-full.sort {
    @media all and (max-width: 400px) {
        font-size: 10.8px;
    }
}

.good-producer__link-small {
    @media all and (max-width: 400px) {
        font-size: 12px;
    }
}

.j-goods-slider .good-producer {
    padding-left: 12px;
    height: 37px;

    .good-producer__link:hover {
        text-decoration: none;
    }
}

.h1--brand,
h1.h1--brand {
    margin-bottom: 0.3em;

    font-family: @special-font-family;
    font-size: 12.96px;
    line-height: 1em;

    @media all and (min-width: 400px) {
        font-size: 15px;
    }

    @media all and (min-width: 500px) {
        font-size: 18px;
    }

    @media all and (min-width: 600px) {
        font-size: 20px;
    }

    @media all and (min-width: 768px) {
        font-size: 22px;
    }
}

.brand__flag {
    width: 26px;
    margin-left: 5px;

    @media all and (min-width: 400px) {
        width: 30px;
    }

    @media all and (min-width: 500px) {
        width: 40px;
    }

    @media all and (min-width: 600px) {
        width: 50px;
    }

    @media all and (min-width: 768px) {
        width: 60px;
    }

    @media all and (min-width: 1100px) {
        width: 73px;
    }
}

.brands__info-wrap div:first-child {
        display: flex;
        flex-direction: column; 
}

.country-wrap {
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    width: fit-content;
    order: 2;
}

.brands__info-wrap,
.stocks-wrap {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}



.brand__photo {
    width: 30%;
    max-width: 250px;
}

.desc_top--brands {
    margin-top: 10px;

    font-size: 12px;

    @media all and (min-width: 400px) {
        margin-top: 14px;
    }

    @media all and (min-width: 500px) {
        margin-top: 20px;
    }

    @media all and (min-width: 768px) {
        margin-top: 30px;
    }

    @media all and (min-width: 1100px) {
        font-size: 16px;
    }
}

.stocks-wrap {
    align-items: stretch;

    font-size: 12px;

    @media all and (min-width: 400px) {
        font-size: 14px;
    }

    @media all and (min-width: 600px) {
        font-size: 16px;
    }

    @media all and (min-width: 768px) {
        margin-top: 10px;
    }

    @media all and (min-width: 1100px) {
        margin-top: 25px;
    }
}

.stocks__text {
    height: 10%;
}

.stocks__img {
    .sizes(70%, 90%);
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
}

.stocks__left {
    width: 67%;
}

.stocks__right {
    width: 18%;

    transform: translate(0, 1em);
}

.brands--subgood-type li,
.brands--good-name {
    font-family: @special-font-family;

    list-style: none;

}

.brands--good-type,
.form-question .label__textarea span {
    font-size: 15px;

    @media all and (min-width: 500px) {
        font-size: 16px;
    }

    @media all and (min-width: 600px) {
        font-size: 18px;
    }

    @media all and (min-width: 768px) {
        font-size: 20px;
    }
}

.brands--subgood-type {
    font-size: 9px;
    text-align: right;

    @media all and (min-width: 400px) {
        font-size: 12px;
    }

    @media all and (min-width: 500px) {
        font-size: 14px;
    }

    @media all and (min-width: 600px) {
        font-size: 16px;
    }

    a {
        text-decoration: underline;

        &:hover {
            text-decoration: none;
        }
    }
}

.brands--good {
    // padding-right: 45%;

    // background-image: url('@{images-folder}list.jpg');
    background-size: 41%;
    background-repeat: no-repeat;
    background-position: right center;
    display: flex;
    justify-content: center;

    @media all and (min-width: 500px) {
        // padding-right: 220px;
        min-height: 130px;

        background-size: 130px;
    }
}

.brands--good-name {
    margin-bottom: 5px;
}

.brands--good-type li:not(:first-of-type) .brands--good-name {
    margin-top: 15px;
}

.collaps-item__name.collaps-item__not {
    padding: 2px 0 0;
    justify-content: flex-start;

    font-family: @title-font-family;
    font-size: 21.6px;
    font-weight: bold;
    line-height: 1em;

    @media all and (min-width: 400px) {
        font-size: 22px;
    }

    @media all and (min-width: 600px) {
        font-size: 25px;
    }
}

.faq {
    margin-right: 10%;

    &__date,
    &__question--message {
        margin-bottom: 0;

        text-align: right;
        font-size: 7.2px;

        @media all and (min-width: 400px) {
            font-size: 10px;
        }

        @media all and (min-width: 768px) {
            font-size: 16px;
        }
    }

    &__question {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        &--author {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            width: min-content;
            margin-right: 10px;

            &-name {
                margin-bottom: 0;
                margin-top: 5px;

                font-family: @special-font-family;
                font-size: 6px;
                text-align: center;

                @media all and (min-width: 400px) {
                    font-size: 10px;
                }

                @media all and (min-width: 768px) {
                    font-size: 12px;
                }

                @media all and (min-width: 1100px) {
                    font-size: 16.67px;
                }
            }
        }

        &--avatar {
            width: 80%;
            margin: 0 auto;
        }

        &--message {
            text-align: center;

            &-wrap {
                min-width: 70%;
                padding: 0.8em 0.2em;

                border: 1px solid #a1a1a1;
                border-radius: 5px;

                @media all and (min-width: 500px) {
                    min-width: auto;
                }

                @media all and (min-width: 768px) {
                    padding: 0.8em;
                }
            }
        }
    }

}

.form-question {
    padding: 35px 0 15px 32%;
    margin-right: 9%;

    background-image: url('@{images-folder}list-check.jpg');
    background-repeat: no-repeat;
    background-position: 0% 25%;
    background-size: 34%;

    border-radius: 5px;

    @media all and (min-width: 500px) {
        padding-left: 150px;

        background-size: 140px;
    }

    @media all and (min-width: 768px) {
        max-width: 1050px;
        margin: 0 auto;
    }

    .form__elem {
        padding: 0.5em;

        font-weight: bold;

        border: 1px solid #a1a1a1;
    }

    .form__elem,
    .btn-default {
        font-size: 7.2px;

        @media all and (min-width: 400px) {
            font-size: 10px;
        }

        @media all and (min-width: 768px) {
            font-size: 16px;
        }
    }

    .label__textarea span {
        width: 100%;
        display: block;

        font-family: @title-font-family;
        font-weight: bold;
        text-align: center;
    }

    .btn-default {
        padding: .2em 1em .1em;

        font-family: @title-font-family;
        font-size: 12px;
        text-transform: uppercase;
        text-shadow: none;

        box-shadow: 1px 1px 3px 0px #727576;

        @media all and (min-width: 400px) {
            font-size: 15px;
        }

        @media all and (min-width: 500px) {
            font-size: 17px;
        }

        @media all and (min-width: 600px) {
            font-size: 22px;
        }
    }

    form {
        @media all and (min-width: 768px) {
            width: 100%;
        }
    }
}

.name-red {
    color: #df5638;
}

.collaps-item__name--review:not(.collaps-item__name--click) {
    padding-left: 150px;
    justify-content: flex-start;

    font-family: @title-font-family;
    font-size: 14px;

    @media all and (min-width: 500px) {
        padding-left: 25px;
        justify-content: center;
    }

    @media all and (min-width: 600px) {
        font-size: 16px;
    }

    @media all and (min-width: 768px) {
        padding-left: 45px;

        font-size: 18px;
    }
}


.collaps-item__title--reviews {
    @media all and (max-width: 767px) {
        padding-top: 6px;
        padding-bottom: 6px;
    }

    @media all and (min-width: 768px) {
        padding-top: 10px;
        padding-bottom: 10px;
    }
}

.review_link {
    color: #ffffff;
    text-decoration: none;

    border-bottom: 1px dashed #ffffff;

    &:hover {
      color: #f89c1d;
      text-decoration: none;

      border-bottom: 1px dashed #f89c1d;
    }
}

.collaps-list .rating--reviews {
    margin-bottom: 10px;
}

.radio-btn-group__item:hover {
    border-color: @primary-color;
}

.review.review-open {
    .review-form__title .radio-btn-group__item:not(:checked) {
        color: #646464;

        background-color: white;
    }

    .review-form__body,
    .collaps-item__footer {
        display: block;
    }

    .review-form__body {
        margin-top: 35px;
    }

    .collaps-item__footer {
        margin-bottom: 5px;
    }
}

.review-form__body {
    .radio-btn-group__item {
        color: @primary-color;
        border-color: @primary-color;

        background-color: white;
    }
}

.review .collaps-item__footer {
    display: none;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
}

.disabled {
    .rating--reviews .icons--star {
        background-image: url('@{icons-folder}star.svg');
    }

    .btn-warning,
    .btn-warning:hover {
        background-color: #1f1f1f;
    }

    .btn-warning:hover {
        transform: none;
        background-image: none;
    }
}

.search__input,
.ya-site-form__input-text {
    padding: 4px 6px;
    height: 28px;
    box-sizing: border-box;

    border: 1px solid #b3b3b3;
    border-right: none;
    border-radius: .3em 0 0 .3em;

    @media all and (min-width: 900px) {
        padding: 4px 13px;
    }
}

.search__wrapper {
    display: flex;
}

button.icons {
    background-color: transparent;
    border: none;

    &:hover {
        cursor: pointer;
    }
}

button.icons--search,
#ya-site-form0 .ya-site-form__submit.ya-site-form__submit_type_image {
    @media all and (min-width: 768px) {
        margin-left: 0;
        .sizes(28px, 28px);
        box-sizing: border-box;

        border-radius: 0 .3em .3em 0;
        background-size: 80%;
        background-position: center;
    }
}

.search__input,
.link-text {
    display: none;
}

.menu-btn {
    display: inline-flex;
    align-items: center;

    &::after {
        @media all and (min-width: 840px) {
            content: '\041C\0435\043D\044E';

            display: inline-block;
            margin-left: 7px;

            color: @base-color;
        }
    }

    &:hover {
        .icons--menu {
            background-image: url('@{icons-folder}burger-light-blue.svg');
        }

        &::after {
            color: @link-color;
        }
    }
}

.search__wrapper:hover {
    .search__input,
    .ya-site-form__input-text,
    .icons--search,
    #ya-site-form0 .ya-site-form__submit.ya-site-form__submit_type_image {
        @media all and (min-width: 768px) {
            border-color: @link-color;
        }
    }

    .icons--search,
    #ya-site-form0 .ya-site-form__submit.ya-site-form__submit_type_image {
        background-image: url('@{icons-folder}search-light-blue.svg') !important;
    }
}

.ya-site-form__search-wrap,
.ya-site-form__search-input {
    padding: 0;
    border: none;
}

.sidr-inner:not(:first-of-type) {
    display: none;
}

.mobile-header__nav {
    @media all and (min-width: 768px) {
        width: 70%;
        max-width: 660px;
    }

    @media all and (min-width: 1200px) {
        max-width: 800px;
    }

    .icons--compare {
        position: relative;
    }

    .count-compare {
        position: absolute;
        top: -9px;
        left: 11px;

        width: 17px;
        display: none;

        text-align: center;
        color: @base-color;
        font-size: 12px;

        border-radius: 50%;
        border: 1px solid @base-color;
        background-color: @attention-color;

        @media all and (min-width: 840px) {
            width: 19px;

            font-size: 14px;
        }
    }

    .icons--compare,
    .icons--shopping-cart,
    .search__wrapper {
        @media all and (min-width: 840px) {
            position: relative;

            padding-right: 105px;
        }

        &:hover {
            background-image: url('@{icons-folder}compare-2-light-blue.svg');

            &::after {
                color: @link-color;
            }
        }

        &::after {
            @media all and (min-width: 840px) {
                content: 'Сравнение';
                position: absolute;
                left: 25px;
                top: 50%;

                color: @base-color;

                transform: translate(0, -50%);
            }
        }
    }

    .search__wrapper.search--open:hover {
        background: transparent;
    }

    .icons--shopping-cart {
        position: relative;

        @media all and (min-width: 840px) {
            padding-right: 90px;
        }

        &:hover {
            background-image: url('@{icons-folder}shopping-cart-light-blue.svg');

            .shopping-cart--quantity {
                background-color: darken(@link-color, 15%);
            }
        }

        &::after {
            @media all and (min-width: 840px) {
                content: 'Корзина';
                left: 28px;
            }
        }

        .shopping-cart--quantity {
            position: absolute;
            top: -9px;
            left: 11px;

            width: 17px;
            display: none;

            text-align: center;
            color: @base-color;
            font-size: 12px;

            border-radius: 50%;
            border: 1px solid @base-color;
            background-color: @attention-color;

            @media all and (min-width: 840px) {
                width: 19px;

                font-size: 14px;
            }

        }
    }

    .search__wrapper {
        @media all and (min-width: 840px) {
            padding-right: 50px;
        }

        &:hover {
            cursor: pointer;
        }

        &::after {
            @media all and (min-width: 840px) {
                content: 'Поиск';
                left: 28px;
            }
        }

        &.search--open {
            max-width: 300px;
            padding-right: 0;

            &::after {
                content: '';
            }

            .icons--search,
            #ya-site-form0 .ya-site-form__submit.ya-site-form__submit_type_image {
                border: 1px solid #b3b3b3;
                border-left: none;
            }
        }
    }
}

.round-slider,
.catalog {
    @media all and (min-width: 768px) {
        border-bottom: none;
    }
}

.catalog__item--head {
    @media all and (min-width: 768px) {
        width: 100%;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }

    picture {
        @media all and (min-width: 768px) {
            display: none;
        }
    }

    .catalog__name {
        @media all and (min-width: 768px) {
            font-size: 26px;
        }
    }

    .catalog__link {
        font-family: @special-font-family;

        @media all and (min-width: 768px) {
            padding: 0;
        }

        &:hover {
            box-shadow: none;
        }
    }
}

.catalog--producers .catalog__item:not(.catalog__item--head) {
    @media all and (min-width: 768px) {
        align-items: center;
    }

    picture {
        max-width: 150px;
    }
}

.elem-list {
    position: relative;

    @media all and (min-width: 768px) {
        /*padding-left: 50px;
        padding-right: 50px;*/
    }

    .bx-wrapper .bx-controls-direction .bx-prev,
    .bx-wrapper .bx-controls-direction .bx-next {
        top: 50%;

        .sizes(30px, 45px);

        transform: translate(0, -50%);
    }

    .bx-wrapper .bx-controls-direction .bx-next {
        transform: rotate(180deg) translate(0, 50%);
    }

    &--goods {
        @media all and (min-width: 768px) {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-around;
        }
    }
}

.footer__content {
    padding-top: 15px;

    @media all and (min-width: 768px) {
        position: relative;

        display: flex;
        flex-direction: column;
        align-items: center;

        font-family: @base-font-family;
    }
}

.footer-list {
    &--menu {
        @media all and (min-width: 768px) {
            order: 1;
            display: flex;
            width: 70%;
            justify-content: space-around;
            margin-bottom: 30px !important;
        }
    }

    &--payment {
        @media all and (min-width: 768px) {
            position: absolute;
            right: 17.5%;
            bottom: 55px;

            text-align: right;
        }

        li:not(.cards) {
            @media all and (min-width: 768px) {
                display: none;
            }
        }
    }

    &--copyright {
        margin-bottom: 20px !important;

        @media all and (min-width: 768px) {
            order: 3;
            margin-bottom: 10px !important;
        }

        .copyright img {
            @media all and (min-width: 768px) {
                margin-left: 10px;
            }
        }
    }

    &--social {
        @media all and (min-width: 768px) {
            order: 2;
            margin-bottom: 15px !important;
        }
    }
}

.social-links__image {
    @media all and (min-width: 768px) {
        max-width: 25px;
        margin-right: 5px;
    }

    &:last-of-type {
        margin-right: 0;
    }
}

.cards__item {
    @media all and (min-width: 768px) {
        max-width: 40px;
        margin-bottom: 0;
        margin-right: 10px;
    }
}

/* sort  select */
.jq-selectbox__select {
    margin-left: 5px;
}

.jq-selectbox__select-text,
.jq-selectbox__dropdown li {
    width: 180px !important;
    padding: 5px 30px 5px 10px;
    margin-bottom: 0;

    font-weight: bold;
    font-size: 13px;
    text-transform: uppercase;
    color: @font-revers-color;

    background-color: #f63f17;
    background-position: 90% center;
    background-repeat: no-repeat;
    background-size: 14px 14px;
    border: none;
    border-radius: 5px;

    @media all and (max-width: 768px) {
        width: 135px !important;
        height: auto;
        padding: 4px 25px 4px 10px;

        font-size: 10px;

        background-size: 8px 8px;
    }

    @media all and (max-width: 400px) {
        font-size: 8.6px;
    }

    &:hover {
        background-color: darken(#f63f17, 5%);

        cursor: pointer;
    }
}

.jq-selectbox__dropdown {
    top: 21px !important;
    left: 5px;

    border: 1px solid @base-color-light;

    @media all and (min-width: 768px) {
        top: 25px !important;
    }
}

.jq-selectbox__dropdown li {
    border-radius: 0;

    &.sort--asc {
        background-image: url('@{icons-folder}up.svg');
    }

    &.sort--desc {
        background-image: url('@{icons-folder}down.svg');
    }
}

.sort--asc .jq-selectbox__select-text {
    background-image: url('@{icons-folder}up.svg');
}

.sort--desc .jq-selectbox__select-text {
    background-image: url('@{icons-folder}down.svg');
}

.good__container {
    @media all and (min-width: 1100px) {
        position: relative;

        width: 49%;
        margin-bottom: 20px;

        border: 1px solid @body-text-color-light;
        border-bottom: none;
    }
}

.collaps-item__name--head {
    @media all and (min-width: 768px) {
        padding: 0;

        font-family: @special-font-family;
        font-size: 26px;
    }
}

.goods-slider {
    @media all and (min-width: 768px) {
        padding: 5px 10px;
    }
}

.header__button {
    display: none;

    border: none;
    background: transparent;

    transition: 0.3s;

    @media all and (min-width: 768px) {
        display: flex;
    }

    @media all and (min-width: 1040px) {
        flex-direction: row;
        align-self: auto;

        font-size: 14px;
    }

    span {
        display: none;

        @media all and (min-width: 1040px) {
            display: inline;
        }
    }

    &:hover {
        color: @link-color;

        background: transparent;

        transform: scale(1.05);
    }

    &::before {
        margin-right: 5px;

        transform: translate(0, 0);
    }
}

button:hover {
    cursor: pointer;
}

.fancybox-wrap:not(.fancybox-wrap--spec) {
    top: 50% !important;
    left: 50% !important;

    max-width: 80%;
    max-height: 60%;
    /*width: auto !important;
    min-width: 320px;*/

    transform: translate(-50%, -50%) !important;
}

.fancybox-type-image {
    height: 100% !important;

    .fancybox-skin,
    .fancybox-outer,
    .fancybox-inner {
        height: 100% !important;
    }
}

.fancybox-type-html {
    width: auto !important;
}

.li-lvl-1 {
    position: relative;

    .a-lvl-1::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 10px;

        .sizes(30px, 30px);

        background-size: contain;
        background-repeat: no-repeat;

        transform: translate(0, -50%);
    }

    &.producers .a-lvl-1::before {
        background-image: url('@{icons-folder}producer.svg');
    }

    &.services .a-lvl-1::before {
        background-image: url('@{icons-folder}service.svg');
    }

    &.basket .a-lvl-1::before {
        background-image: url('@{icons-folder}shopping-cart.svg');
    }

    /*&.compare .a-lvl-1::before {
        background-image: url('@{icons-folder}compare-2.svg');
    }*/

    &.discount .a-lvl-1::before {
        left: 15px;

        background-image: url('@{icons-folder}fire.svg');
    }

    &.pay .a-lvl-1::before {
        background-image: url('@{icons-folder}pay.svg');
    }

    &.delivery .a-lvl-1::before {
        left: 6px;

        .sizes(38px, 24px);

        background-image: url('@{icons-folder}delivery.svg');
    }

    &.email .a-lvl-1::before {
        left: 6px;

        .sizes(45px, 30px);

        background-image: url('@{icons-folder}mail.svg');
    }

    &.phone .a-lvl-1::before {
        background-image: url('@{icons-folder}call.svg');
    }
}

.form--call-me,
.form--text-me {
    padding: 0;

    background-clip: border-box;

    /*.j-ajax_form {
        background-color: #171b28;
    }*/
}

.form__head {
    position: relative;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 25px 25px 0;

    /*background-image: linear-gradient(140deg, #454955, #171b28 75%, #272b38);
    background-color: #171b28;*/
    border-bottom: 2px solid @base-color;

    @media all and (min-width: 768px) {
        padding: 35px 35px 0;
    }

    &::before,
    &::after {
        position: absolute;
        content: '';

        display: block;

        background-size: contain;
        background-repeat: no-repeat;
    }

    &::before {
        top: 50%;
        left: 25px;

        .sizes(55px, 36px);

        background-image: url('@{images-folder}logo-night0.png');
        /*logo-sm.png*/

        transform: translate(0, -50%);

        @media all and (min-width: 768px) {
            left: 35px;

            .sizes(95px, 62px);
        }
    }

    &::after {
        top: 30px;
        right: 25px;

        .sizes(55px, 55px);

        background-image: url('@{icons-folder}phone-simple.svg');
        border: 1px solid @base-color;

        transform: scale(-1, 1);

        @media all and (min-width: 768px) {
            top: 40px;
            right: 35px;

            .sizes(90px, 90px);
        }
    }
}

.form--text-me .form__head::after {
    background-image: url('@{icons-folder}mail.svg');
    border: none;
}

.form__tel,
.form__head {
    color: @base-color;
    font-family: @title-font-family;
    font-weight: bold;
    font-size: 22px;
    line-height: 1em;

    @media all and (min-width: 768px) {
        font-size: 30px;
    }
}

a.form__tel {
    width: auto;

    border-bottom: 1px solid @base-color;

    &:hover {
        text-decoration: none;

        border-bottom: 1px solid transparent;
    }
}

.form__tel,
.form__head p {
    margin-bottom: 10px;
}

.checkbox__wrapper {
    position: relative;

    width: 100%;
}

.form__button .btn-default {
    font-family: @title-font-family;
    font-size: 18px;
    font-weight: bold;
    text-shadow: none;

    @media all and (min-width: 768px) {
        font-size: 24px;
    }
}

.form--text-me,
.form--call-me {
    .form__button {
        width: 30%;
        min-width: 160px;
        margin-bottom: 10px;

        @media all and (min-width: 768px) {
            position: relative;
            z-index: 5;
            right: 10px;

            min-width: 0;
            margin-right: 15px;
            margin-bottom: 0;
        }

        .btn-default {
            height: 1.5em;
            padding: 3px 15px 5px;
        }
    }

    .form__elem {
        width: 65%;
        padding: 8px 14px;
        height: 1.5em;

        font-family: @title-font-family;
        color: @base-color;
        font-size: 18px;

        background-color: transparent;
        border: 2px solid @base-color;

        @media all and (min-width: 768px) {
            font-size: 24px;
        }

        &:-webkit-autofill,
        &:-webkit-autofill:hover,
        &:-webkit-autofill:focus {
            color: @base-color  !important;

            background-color: transparent !important;
        }


        &:hover {
            border-color: @link-color;
        }
    }

    .form {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        padding: 10px 15px 20px;

        /*background-image: linear-gradient(140deg, #454955, #171b28 75%, #272b38);*/
    }

    .f-underline {
        margin-left: 0.1em;
    }

    .checkbox__wrapper:hover {
        .f-underline {
            text-decoration: none;
        }

        .label--check {
            cursor: pointer;

            ::before {
                box-shadow: inset 0 0 2px 1px @link-color;
            }
        }
    }

    input::placeholder,
    textarea::placeholder {
        color: @base-color;
    }
}

.label--check {
    display: flex;

    font-family: @title-font-family;
    color: @base-color;
    font-size: 12px;
    font-weight: bold;

    @media all and (min-width: 768px) {
        font-size: 14px;
    }

    &::before {
        content: '';

        display: block;
        .sizes(14px, 14px);
        margin-right: 7px;

        background-color: @base-color;

        @media all and (min-width: 768px) {
            .sizes(17px, 17px);
            margin-right: 10px;
        }
    }

    &:hover {
        cursor: pointer;

        .f-underline {
            cursor: pointer;
        }
    }
}

.check:checked + .label--check::before {
    content: '\2713';

    color: #000000;
    font-weight: bold;
    font-size: 19px;
    line-height: 17px;
    text-align: center;
}

.fancybox-inner--form {
    height: 100% !important;

    border-radius: 10px;
    background-color: transparent;
}

input[type="file"] {
    display: none;
}

.input--file,
.multiload__file {
    position: relative;

    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;

    color: @base-color;
    font-family: @title-font-family;
    font-size: 18px;
    font-weight: bold;
}

.multiload__file {
    font-size: 14px;

    border-top: none;
}

.multiload__file-name {

    flex-grow: 1;

    word-break: break-word;
}

.multiload__delete-file {
    width: 20px;
    margin-left: 15px;

    font-size: 0;

    background-color: transparent;
    border: none;

    transition: .3s;

    &:hover {
        transform: scaleX(-1);
    }
}

.input--file::after,
.multiload__delete-file::after {
    display: block;

    color: @base-color;
    line-height: 18px;
}

.input--file {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.input--file::before {
    content: '';

    display: block;
    .sizes(27px, 24px);
    margin-right: 5px;

    background-image: url('@{icons-folder}add-file.svg');
    background-size: contain;
    background-repeat: no-repeat;
}

.multiload__delete-file::after {
    content: '×';

    font-size: 25px;

    transform: translate(0, -4px);
}

.input--file:hover,
.multiload__delete-file {
    cursor: pointer;
}

.multiload {
    min-width: 50px;
}

.form--text-me {
    .form__button {
        order: 2;
        width: 100%;
        margin-right: 0;
        margin-top: 5px;
    }

    .form__elem {
        width: 100%;
    }

    .checkbox__wrapper {
        width: auto;
    }

    .form {
        justify-content: space-between;
        align-items: center;
    }

    textarea.form__elem {
        height: 4em;
    }

    .checkbox__wrapper,
    .multiload {
        margin-bottom: 10px;
    }
}

.brand {
    &__wrapper {
        display: flex;
        flex-direction: column;

        .brand__flag {
            height: 1.2em;
            width: auto;
            margin-left: 0.4em;
        }
    }

    &__link {
        position: relative;

        display: flex;
        align-items: center;
        height: 1.7em;
        margin-bottom: 0.5em;
        padding-left: 0.5em;

        font-family: @special-font-family;
        font-size: 12.96px;
        line-height: 1em;
        color: @base-color;

        background-color: @primary-color;

        @media all and (min-width: 500px) {
            font-size: 17px;
        }

        @media all and (min-width: 768px) {
            font-size: 22px;
        }

        &::after {
            content: '';
            position: absolute;
            right: 16%;
            top: -0.2em;

            .sizes(5.55em, 1.4em);

            background-image: url('@{images-folder}info-yellow.png');
            background-size: contain;
            background-repeat: no-repeat;
        }
    }

    &__first-letter {
        padding-top: 3em;
        margin-top: -1em;

        font-family: @special-font-family;
        font-size: 17px;
        text-transform: uppercase;
        line-height: 1em;

        @media all and (min-width: 500px) {
            font-size: 22px;
        }

        @media all and (min-width: 768px) {
            font-size: 27px;
        }

        &:not(:first-of-type) {
            padding-top: 2em;
        }
    }
}

.mobile-header.open-search {
    .logo__wrap {
        @media all and (max-width: 767px) {
            width: 23%;
            max-width: 140px;
            margin-left: 10px;
        }
    }

    .mobile-header__nav {
        @media all and (max-width: 767px) {
            flex-grow: 1;
            margin-left: 10px;
            max-width: 300px;
        }

        .search__input,
        .ya-site-form__input-text,
        #ya-site-form0 .ya-site-form__search-input-layout-l,
        #ya-site-form0 .ya-site-form__input {
            @media all and (max-width: 767px) {
                width: 170px !important;
            }

            @media all and (max-width: 530px) {
                width: 140px !important;
            }

            @media all and (max-width: 380px) {
                width: 110px !important;
            }
        }

        .icons--search,
        #ya-site-form0 .ya-site-form__submit.ya-site-form__submit_type_image {
            @media all and (max-width: 767px) {
                .sizes(28px, 28px);

                background-position: center;
                background-size: 85%;
                border-radius: 0 5px 5px 0;
            }
        }
    }
}

.h1--brand.f-22 {
    font-size: 22px;

    @media all and (min-width: 500px) {
        font-size: 25px;
    }

    @media all and (min-width: 768px) {
        font-size: 30px;
    }
}

.all-content__content.brands {
    position: relative;

    .brand__letters {
        width: 3em;
        display: inline-flex;
        flex-direction: column;
        justify-content: center;

        background-color: @primary-color;
    }

    .brand__letters-wrapper {
        position: fixed;
        right: 0;
        top: 55px;

        text-align: right;

        .brand__link-letter {
            display: block;
            margin: 0.15em 0.3em;

            color: @base-color;
            font-family: @special-font-family;
            font-size: 20px;
            text-transform: uppercase;
            line-height: 1em;
            text-align: center;

            @media all and (min-width: 500px) {
                font-size: 22px;
            }

            @media all and (min-width: 768px) {
                font-size: 25px;
            }

            &:last-of-type {
                margin-bottom: 0.3em;
            }

            &:first-of-type {
                margin-top: 0.3em;
            }
        }
    }
}

.s-file-mess-conteiner {
    width: 100%;
}

.cart {
    &__container {
        max-width: @content-width;
        margin: 0 auto;
        flex-grow: 1;
    }

    &__btn,
    &__title,
    &__banner--text {
        font-family: @special-font-family;
    }

    &__title {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        margin: 10px 10px 0;

        font-size: 18px;
        color: @primary-color;

        @media all and (min-width: 500px) {
            font-size: 20px;
        }

        @media all and (min-width: 768px) {
            margin: 15px 15px 0;

            font-size: 22px;
        }

        &::after {
            content: '';

            display: block;
            .sizes(42px, 38px);

            background-image: url('@{images-folder}cart.jpg');
            background-size: contain;
            background-repeat: no-repeat;

            @media all and (min-width: 768px) {
                .sizes(75px, 71px);
            }
        }

        &.cart__title--black {
            margin-right: 0;
            margin-left: 0;
            padding: 0.3em 0.5em;
            align-items: center;

            color: @base-color;

            background-color: @primary-color;

            &::after {
                display: none;
            }
        }
    }

    &__block,
    &__quantity {
        display: flex;
    }

    &__block {
        position: relative;

        padding: 0.5em;

        border-top: 1px solid #ababab;

        @media all and (min-width: 768px) {
            align-items: center;
        }
    }

    &__quantity {
        margin-left: 15%;
        align-items: center;

        @media all and (min-width: 768px) {
            position: absolute;
            top: 50%;
            right: 15px;

            width: 15%;
            min-width: 150px;
            min-width: 150px;
            margin-left: 0;
            justify-content: flex-end;

            transform: translate(0, -50%);
        }
    }

    &__img {
        width: 20%;
        min-width: 75px;
        max-width: 150px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__price,
    &__text--wrapper,
    &__quantity--input {
        font-family: @title-font-family;
    }

    &__text--wrapper {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-end;

        font-weight: bold;
        font-size: 17.3px;

        @media all and (min-width: 600px) {
            font-size: 20px;
        }

        @media all and (min-width: 768px) {
            width: 100%;

            font-size: 25px;
        }

        @media all and (min-width: 1024px) {
            flex-wrap: nowrap;
            align-items: center;
        }
    }

    &__name {
        width: 100%;
        margin-left: 15%;

        font-size: 15px;

        @media all and (min-width: 600px) {
            font-size: 18px;
        }

        @media all and (min-width: 768px) {
            font-size: 22px;
        }

        @media all and (min-width: 1024px) {
            width: auto;
            margin-left: 1em;
        }

        &:last-of-type {
            margin-top: 0.2em;

            @media all and (min-width: 768px) {
                margin-top: 0;
            }
        }
    }

    &__producer--flag {
        width: 15%;
        max-width: 50px;
        margin-right: 5px;
        object-fit: contain;

        @media all and (min-width: 1100px) and (max-width: 1200px) {
            display: none;
        }
    }

    &__quantity--input {
        .sizes(1.3em, 1.2em);

        font-weight: bold;
        font-size: 30px;
        text-align: center;

        border: 1px solid #d9d9d9;
        border-radius: 5px;
    }

    &__quantity--btn {
        .sizes(23px, 19px);

        background-color: transparent;
        background-image: url('@{icons-folder}arrow_cart.svg');
        background-size: contain;
        border: none;

        transition: 0.2s;

        &:hover {
            transform: scale(1.2);
        }

        &.decrease {
            transform: rotate(-180deg) translate(3px, -4px);

            &:hover {
                transform: rotate(-180deg) translate(3px, -4px) scale(1.2);
            }
        }

        &s {
            display: flex;
            flex-direction: column;
            margin-left: 0.5em;
            margin-right: 1em;
        }

        &-delete {
            text-decoration: underline;

            background-color: transparent;
            border: none;

            &:hover {
                text-decoration: none;
            }

            @media all and (min-width: 600px) {
                font-size: 14px;
            }

            @media all and (min-width: 768px) {
                font-size: 16px;
            }
        }
    }

    &__delivery,
    &__weight,
    &__btn {
        font-size: 9px;
        white-space: nowrap;

        @media all and (min-width: 500px) {
            font-size: 12px;
        }

        @media all and (min-width: 600px) {
            font-size: 14px;
        }

        @media all and (min-width: 768px) {
            font-size: 16px;
        }
    }

    &__price-block,
    &__center {
        margin-bottom: 0;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: auto;

        @media all and (min-width: 1024px) {
            flex-direction: row;
        }
    }

    &__center {
        flex-grow: 1;

        /*@media all and (min-width: 768px) {
            width: 35%;
        }

        @media all and (min-width: 1024px) {
            width: 50%;
        }*/
    }

    &__price-block {
        justify-content: space-around;

        @media all and (min-width: 768px) {
            position: absolute;
            top: 50%;
            /*right: 200px;*/
            right: 150px;
            width: 30%;
            max-width: 150px;
            align-items: center;

            transform: translate(0, -50%);
        }

        @media all and (min-width: 1024px) {
            max-width: 250px;
        }

        @media all and (min-width: 1100px) {
            right: 85px;
        }
    }

    &__price {
        margin-bottom: 0;

        color: #c5201b;
        font-weight: normal;
        text-align: right;
        line-height: 1em;

        @media all and (min-width: 500px) {
            font-size: 14px;
        }

        @media all and (min-width: 600px) {
            font-size: 17px;
        }

        @media all and (min-width: 768px) {
            font-size: 23px;
        }
    }

    &__producer {
        line-height: 1em;
    }

    &__btn--wrapper,
    &__sum {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__sum {
        padding: .5em;

        border-top: 1px solid #000000;
        border-bottom: 1px solid #000000;
    }

    &__weight {
        margin-bottom: 0;

        font-family: @special-font-family;
    }

    &__btn--wrapper {
        margin: 20px 0 25px;
        padding: 0 2em 1em;

        @media all and (min-width: 768px) {
            margin: 40px 0 45px;
        }
    }

    &__btn {
        position: relative;

        display: flex;
        align-items: center;
        justify-content: center;
        height: 2.5em;
        width: 11em;

        color: @base-color;

        background-color: #252525;
        border: none;
        border-radius: 5px;

        transition: 0.3s;

        &::before {
            content: '';
            position: absolute;
            bottom: -0.6em;
            .z-index(0);

            .sizes(150%, 1em);

            background: radial-gradient(ellipse at center, rgba(43, 43, 43, 0.4) 0%, rgba(43, 43, 43, 0) 80%, rgba(43, 43, 43, 0) 100%);
        }

        &:hover {
            background-color: darken(#252525, 10%);

            text-decoration: none;
            transform: scale(1.05);
        }

        &--red {
            background-color: @warning-color;

            &:hover {
                background-image: linear-gradient(0deg, darken(@warning-color, 10%), darken(@warning-color, 10%));
            }
        }
    }

    &__banner {
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        .sizes(320px, 110px);
        margin: 0 auto 30px;
        padding: 10px;

        background-image: url('@{images-folder}delivery-t.jpg');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        border-radius: 15px;

        @media all and (min-width: 500px) {
            .sizes(450px, 155px);

            border-radius: 20px;
        }

        @media all and (min-width: 650px) {
            .sizes(550px, 195px);
        }

        @media all and (min-width: 768px) {
            .sizes(700px, 245px);
            margin-bottom: 50px;

            border-radius: 30px;
        }

        @media all and (min-width: 1100px) {
            .sizes(930px, 322px);
        }

        &--text {
            margin-bottom: 0;

            font-size: 18px;
            color: #fdf1ed;
            text-shadow: 0 0 5px #000000;
        }
    }
}

.fix-height-6em {
    height: 6em;
}

.form--order {
    margin: 0 auto;
    max-width: 700px;
    padding: 0 10px 10px;
    font-size: 15px;

    .subtitle {
        margin-top: 25px;

        font-family: @title-font-family;
        font-size: 18px;
        font-weight: bold;
        color: @primary-color;
        text-align: center;

        @media all and (min-width: 500px) {
            font-size: 21px;
        }

        @media all and (min-width: 768px) {
            font-size: 25px;
        }
    }

    .note {
        margin-top: -5px;

        font-size: 10px;
        color: #a1a2a5;

        @media all and (min-width: 500px) {
            font-size: 11px;
        }

        @media all and (min-width: 768px) {
            font-size: 12px;
        }
    }

    .form__elem {
        border-color: #e9e9e9;
        border-radius: 0;
        box-shadow: 0px 0px 2px 2px #f6f6f6;

        &:hover,
        &:focus {
            border-color: @primary-color-light;
        }

        &--region {
            position: relative;

            .label--region {
                position: absolute;
                right: 0.5em;
                top: 0.4em;

                font-size: 9px;
            }
        }

        &--half {
            width: 48%;
        }
    }

    .flex__row {
        flex-wrap: wrap;
    }

    .btn-default {
        font-weight: normal;
        font-size: 18px;

        background-color: @primary-color;
        box-shadow: 0px 1px 2px 1px rgba(0, 6, 8, 0.5);

        &:hover {
            background: @primary-color;
        }

        @media all and (min-width: 500px) {
            font-size: 20px;
        }

        @media all and (min-width: 768px) {
            font-size: 22px;
        }
    }

    .item {
        &-full {
            width: 100%
        }

        &-half {
            width: 49%
        }
    }
}

.radio {
    &__label {
        position: relative;

        display: flex;
        align-items: center;

        &::before {
            content: '';

            display: block;
            margin-right: 5px;
            .sizes(21px, 21px);

            border-radius: 50%;
            background-color: #ededed;
            border: 1px solid #ececec;
        }

        &:hover {
            cursor: pointer;

            &::before {
                border-color: @link-color;
            }
        }
    }

    &__input:checked + .radio__label::after {
        content: '';
        position: absolute;
        top: 5px;
        left: 5px;

        .sizes(11px, 11px);
        border-radius: 50%;
        background-color: #666666;
        border: 1px solid #494949;
        outline: 1px solid #f6f6f6;
    }

    &__input:focus + .radio__label::before {
        border-color: @link-color;
    }

    &__wrapper {
        position: relative;

        &:not(:last-of-type) {
            margin-right: 1em;
        }
    }
}

.s-payment {
    .z-index(8) !important;

    width: 100%;
    margin-bottom: 10px;

    line-height: 2.5em;

    border: 1px solid #e9e9e9;
    border-radius: 0;
    box-shadow: 0px 0px 2px 2px #f6f6f6;

    &:hover,
    &:focus {
        border-color: @primary-color-light;
    }

    &.opened .jq-selectbox__select::after {
        transform: translate(0, -50%) rotate(180deg);
    }

    .jq-selectbox__dropdown li,
    .jq-selectbox__select-text {
        width: 100% !important;

        color: #858585;/*@body-text-color;*/
        font-family: @title-font-family;
        font-weight: bold;
        font-size: 15px;

        background-color: @base-color;

        @media all and (min-width: 500px) {
            font-size: 17px;
        }

        @media all and (min-width: 768px) {
            font-size: 20px;
        }
    }

    .jq-selectbox__select-text {
        padding: 0 15px;
    }

    .jq-selectbox__dropdown li {
        padding-top: 0;
        padding-bottom: 0;

        background-image: none;

        &:hover {
            background-color: #e9e9e9;
        }

        &.disabled {
            color: #a1a2a5;

            &:hover {
                background-color: @base-color;
            }
        }
    }

    .jq-selectbox__dropdown {
        top: 37px !important;
        left: 0px !important;

        width: 100% !important;

        border-color: #e9e9e9;
    }

    .jq-selectbox__select {
        margin-left: 0;

        &::after {
            position: absolute;
            right: 15px;
            top: 50%;

            content: '';
            cursor: pointer;

            /*.sizes(30px, 22px);*/
            .sizes(14px, 11px);

            background-image: url('@{icons-folder}arrow-40168.png');
            background-size: contain;
            background-repeat: no-repeat;

            transform: translate(0, -50%);
        }
    }
}

.check {
    cursor: pointer;
}

select.s-payment {
    .z-index(0) !important;
}

.with-link-main {
    &::before {
        display: none;
    }
}

.sidr ul li a.link-main {
    position: absolute;
    top: 50%;
    left: 0;

    display: flex;
    align-items: center;

    font-family: @title-font-family;
    font-size: 20px;
    line-height: 1em;
    color: #ffffff;

    transform: translate(0, -50%);

    &:hover {
        text-decoration: underline;
    }

    &::before {
        content: '';

        display: block;
        margin-right: 5px;
        .sizes(20px, 29px);

        background-image: url('@{images-folder}logo-sm.png');
        background-size: contain;
        background-repeat: no-repeat;
    }
}

button.collaps-item__like,
button.collaps-item__sharing {
    border: none;
    background-color: transparent;
    outline: none;
}

.collaps-item__title--reviews .collaps-item__like {
    color: #ffffff;
}

.goods-table .collaps-item__title--reviews .collaps-item__like {
    color: #000000;
}

.current_goog_type {
    order: -1;

    font-weight: bold;

    &.main_url {
        pointer-events: none;
    }
}

#sort-styler {
    z-index: 7 !important;
}

.fancybox-lock {
    .header {
        position: absolute;
    }
}



#sidr-search.sidr,
#search {
    width: 300px;
    height: ~'calc(100% - 54px)';
    overflow: auto;

    color: #000000;
    font-family: Impact, Charcoal, sans-serif;

    background-color: #ffffff;

    p {
        font-size: 18px;
    }

    .range__input {
        margin-bottom: 0;
        margin-left: 5px;
        width: 80%;

        font-size: 13.5px;
        color: #000000;
        font-family: Impact, Charcoal, sans-serif;

        background-color: #ffffff;
        border: 1px solid #444444;
        border-radius: 5px;
    }

    .form-search {
        margin: 0;
        padding: 25px;
        box-sizing: border-box;
    }

    .range {
        position: relative;

        width: 95%;
        height: 3px;
        margin: 22px auto 26px;
        box-sizing: border-box;

        background-color: @primary-color;
        border-radius: 10px;

        outline: none;

        @media all and (min-width: 768px) {
            width: 100%;
        }
    }

    .ui-slider-range {
        height: 100%;

        background-color: @primary-color;
    }

    .ui-slider-handle {
        position: absolute;
        top: -10px;
        margin-left: -10px;
        z-index: 200;

        width: 23px;
        height: 23px;

        background: #ffffff;
        border-radius: 50%;
        border: 10px solid @primary-color;
        cursor: pointer;
    }

    .range__label,
    .range__label label {
        display: flex;
        align-items: center;
    }

    .range__label label:not(:last-of-type) {
        margin-right: 15px;
    }

    .select__param {
        padding-bottom: 17px;
        margin-bottom: 8px;

        border-bottom: 2px solid #717171;
    }

    .checkbox {
        position: absolute;
        left: -9999px;

        & + .search__span {
            display: flex;
            align-items: center;

            &::before {
                content: '';

                display: block;
                margin-right: 8px;
                .sizes(23px, 23px);

                border-radius: 50%;
                border: 1px solid #adadad;
            }
        }

        &:checked + .search__span::before {
            content: '';
            border: none;
            background-color: @primary-color;
            background-image: url('@{images-folder}check-search.png');
            background-size: contain;
            background-repeat: no-repeat;
        }
    }

    .search__flag {
        margin-left: 6px;
        max-width: 30px;
    }

    .label {
        display: block;
        margin-bottom: 6px;

        color: #5c5c5c;
        font-size: 18px;

        &:hover {
            cursor: pointer;
            text-decoration: underline;
        }
    }

    .count__item:last-child .label {
        margin-bottom: 0;
    }

    .show--global {
        margin-bottom: 20px;

        font-size: 13.5px;

        .show-all {
            justify-content: center;
        }
    }

    .show-all,
    .show-up {
        display: flex;
        align-items: center;
        margin-top: 10px;
        margin-bottom: -10px;

        font-size: 11px;
        text-decoration: underline;

        &:hover {
            text-decoration: none;
            cursor: pointer;
        }

        &::after {
            display: none;
        }

        &::before {
            content: '';

            display: block;
            .sizes(13px, 15px);
            margin-right: 6px;

            background-image: url('@{icons-folder}arrow-40168.svg');
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
        }
    }

    .show-up {
        &::before {
            transform: scale(1, -1);
        }
    }
}

.top-block #search {
    @media all and (max-width: 1099px) {
        display: none;
    }

    @media all and (min-width: 1100px) {
        display: block !important;
        width: 100%;
        height: auto;
        padding: 0;
    }
}

.j-count__div {
    display: none;
}

.compare-page .table-with-scroll-wrapper {
    width: 100%;
    overflow-x: scroll;

    font-family: @base-font-family;

    .left-table th {
        font-weight: 700;
    }

    .btn-default {
        padding-left: 5px;
        padding-right: 5px;

        @media all and (min-width: 768px) {
            padding-left: 15px;
            padding-right: 15px;
        }
    }
}

.left-table th,
.right-table-wrapper td {
    vertical-align: middle;
}

h1.collaps-item__name {
    display: inline-flex;
}

.count-basket {
    display: none;
}

.good-chars__label {
    white-space: nowrap;
}
.good-chars__field {
    text-align: right;
}

.dots {
    flex-grow: 1;
    min-width: 10px;
    height: 1em;
    border-bottom: 1px dotted;
}

#reviews_block,
#characteristic {
    padding-top: 50px;
    margin-top: -50px;
}

.delivery-item {
    max-width: 800px;
    width: 100%;
    padding: 15px 5px 12px;

    font-family: @special-font-family;

    &:not(:last-of-type) {
        border-bottom: 1px solid #000000;
    }

    &__wrapper {
        display: flex;
        flex-direction: column;
        margin-left: 10px;

        @media all and (min-width: 768px) {
            margin-left: 15px;
        }
    }

    &__title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 1em;

        font-size: 16px;

        &::after {
            display: block;
            width: 3em;
            min-width: 3em;
            height: 3em;
            margin-left: 10px;

            content: '';
            background-repeat: no-repeat;
            background-size: contain;
        }
    }

    &--del::after {
        background-image: url(/images/del-icon.jpg);
    }

    &--zhel::after {
        background-image: url(/images/zhel-icon.jpg);
    }

    &--pek::after {
        background-image: url(/images/pek-icon.jpg);
    }

    &__subtitle {
        font-size: 12px;
    }

    &__table {
        width: ~ 'calc(100% + 17px)';
        min-width: 320px;
        margin-left: -10px;

        font-size: 10px;
        font-family: @base-font-family;

        border-spacing: 0.5em;
        border-collapse: separate;

        @media all and (min-width: 390px) {
            font-size: 12px;
        }

        @media all and (min-width: 480px) {
            font-size: 14px;
        }

        td {
            width: 14%;
            border: none;
        }

        th {
            background-color: #ebebeb;
        }

        td,
        th {
            padding: 0.5em;
        }
    }
}

.liked {
    color: @attention-color;
}

.desc {
    font-family: @title-font-family;

    p {
        margin-bottom: 0;
    }
}

.ul--dash li {
    display: flex;
    align-items: flex-start;

    &::before {
        margin-right: 5px;

        content: '-';
    }
}

.ul--check,
.ul-check {
  list-style: none;

  li {
    &::before {
        left: 5px;
        top: -3px;

        height: auto;
        width: auto;
        margin-right: 5px;

        background-color: transparent;
        color: @primary-color;
        border-radius: 0;
        content: '\2714';
        font-size: 18px;
    }
  }
}

.brands .container {
    display: flex;
    flex-direction: column;
}

.cart__btn.disabled {
    background-color: #252525;

    pointer-events: none;

    &:hover {
        background-color: #252525;

        text-decoration: none;
        transform: none;
        cursor: default;
    }
}

.no-content {
    display: block;
    margin: 1em auto 0;
    width: 90%;
    max-width: 700px;
    height: auto;
}

.pop-up_form:not(.form-question) {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    /*padding: 25px;*/

    color: #ffffff;

    background-image: linear-gradient(140deg, #454955, #171b28 75%, #272b38);
    background-color: #171b28;

    .h3 {
        margin-bottom: 0;
    }
}

.catalog--producers,
.catalog--subcategories {
    @media all and (max-width: 767px) {
        justify-content: flex-start;
    }
}

.form--doc {
    min-width: 300px;

    .form__elem {
        max-width: 300px;
        margin: 10px auto;
        display: block;
    }

    .title {
        font-size: 1.5em;
    }
    .label {
        margin-bottom: 6px;
        &:hover .label--check::after {
            opacity: 0.5;
        }
    }
    .label--check-round {
        position: relative;

        font-family: @base-font-family;
        font-weight: normal;

        &::before,
        &::after {
            border-radius: 50%;
        }

        &::after {
            position: absolute;
            left: 4px;
            top: 4px;

            width: 9px;
            height: 9px;

            background-color: #4c505c;

            content: '';
            opacity: 0;
        }
    }
    .check:checked+.label--check-round {
        &::before {
            content: '';
        }

        &::after {
            opacity: 1;
        }
    }
    .check-label {
        display: flex;

        @media all and (max-width: 767px) {
            flex-direction: column;
        }

        @media all and (min-width: 768px) {
            align-items: center;
        }

    }
    .check-input {
        max-width: 300px;
        margin-left: auto;
        margin-right: auto;
        background: white;

        &[disabled] {
            background: #6f6d7b;
            border-color: #2c3844;
        }

        @media all and (max-width: 767px) {
            margin-top: 5px;
        }
    }

    .check:checked + .label--check::before {
        @media all and (max-width: 767px) {
            font-size: 15px;
            line-height: 13px;
        }
    }

    .reason {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-bottom: 10px;

        background: rgba(255,255,255,0.1);
    }
}

.review_result {
    flex-direction: column;
    padding: 0;
    min-width: 280px;

    background-image: none!important;
    background-color: @base-color !important;

    .form__elem,
    .checkbox__wrapper {
        width: 90%;
    }

    .form__elem {
        padding: 5px 10px;
        border-radius: 10px;

        font-family: @title-font-family;
        font-size: 19px;

        &::placeholder {
            text-align: center;
        }
    }

    .btn-default {
        margin-top: 0.5em;
        padding: 5px 1em 3px;

        font-size: 20px;
        line-height: 1em;

        @media all and (min-width: 500px) {
            font-size: 22px;
        }

        @media all and (min-width: 768px) {
            font-size: 24px;
        }

    }

    &__header {
        position: relative;

        display: flex;
        flex-direction: column;
        justify-content: flex-end;

        width: 100%;
        padding: 10px 10px 6px 25%;

        background-size: cover;
        background-image: url('@{images-folder}konkurs-head.png');
        background-color: @primary-color;

        &::before {
            position: absolute;
            top: 5px;
            left: 5px;

            width: 25%;
            height: 95%;

            background-image: url('@{images-folder}logo-night0.png');
            background-repeat: no-repeat;
            background-size: contain;
            content: '';
        }
    }

    &__form form,
    &__form,
    &__img {
        width: 100%;
    }

    &__title {
        margin-top: 0.6em;
        margin-bottom: 6px !important;

        font-size: 25px;

        @media all and (min-width: 500px) {
            font-size: 30px;
        }

        @media all and (min-width: 768px) {
            font-size: 40px;
        }

        &--small {
            margin-bottom: 0 !important;

            font-size: 20px;

            @media all and (min-width: 500px) {
                font-size: 24px;
            }

            @media all and (min-width: 768px) {
                font-size: 27px;
            }
        }
    }
    &__title,
    &__title--small {
        font-family: @title-font-family;
        line-height: 1em;
        text-shadow: 1px 1px 2px @body-text-color, -1px -1px 2px @body-text-color;
    }

    &__form form {
        display: flex;
        flex-direction: column;
        align-items: center;

        background-size: cover;
        background-image: url('@{images-folder}konkurs-body.png');
        background-color: @primary-color;
    }

    &__link {
        align-self: flex-end;
        margin: 0 10px 10px 0;

        font-family: @title-font-family;
        font-size: 12px;
        line-height: 1em;
        color: @base-color;

        text-decoration: underline;

        @media all and (min-width: 500px) {
            font-size: 14px;
        }

        @media all and (min-width: 768px) {
            font-size: 16px;
        }

        &:hover {
            text-decoration: none;
        }
    }
}

.form__elem--wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;

    .form__elem {
        width: 65%;
        margin-bottom: 0;
    }

    .form__button {
        width: 30%;
    }
}

.share {
    width: 300px;
    padding: 10px;

    font-family: @title-font-family;
    font-size: 16px;
    color: @base-color;

    background-color: @base-revers-color;
    background-image: url(/images/share-bg.jpg);
    background-repeat: no-repeat;
    background-size: cover;

    @media all and (min-width: 768px) {
        width: 500px;
    }

    @media all and (min-width: 1200px) {
        width: 820px;
    }

    &__img {
        max-width: 60px;

        @media all and (min-width: 500px) {
            max-width: 90px;
        }

        @media all and (min-width: 600px) {
            max-width: 120px;
        }

        @media all and (min-width: 768px) {
            max-width: 140px;
        }
    }

    &__title {
        text-shadow: -1px -1px 2px #000000, 1px 1px 2px #000000;
    }

    &__wrapper {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    &__link {
        width: 30%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        color: @base-color;

        &::before {
            display: block;
            height: 57px;
            width: 100%;

            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;

            content: '';

            @media all and (min-width: 768px) {
                height: 102px;
            }
        }

        &--tg::before {
            background-image: url(/images/share-tg.png);
        }

        &--fb::before {
            background-image: url(/images/share-fb.png);
        }

        &--wu::before {
            background-image: url(/images/share-wu.png);
        }

        &--viber::before {
            background-image: url(/images/share-viber.png);
        }

        &--mail::before {
            background-image: url(/images/share-mail.png);
        }

        &--vk::before {
            background-image: url(/images/share-vk.png);
        }
    }
}

.tab {
    &s {
        display: flex;
        align-items: stretch;

        border: 1px solid @primary-color;
        border-radius: @base-border-radius;
    }

    &__radio {
        position: static;

        width: 0px;
        font-size: 0;
        opacity: 0;

        &:checked + .tab__label {
            color: @base-color;

            background-color: @primary-color;
            border-radius: 0.2em;
        }

        &:not(:checked) + .tab__label:hover {
            color: @primary-color;
        }
    }

    &__label {
        width: 50%;
        padding: 3px 0 1px;

        font-family: @title-font-family;
        font-size: 15px;
        font-weight: bold;
        color: #858585;
        text-align: center;

        cursor: pointer;

        @media all and (min-width: 500px) {
            font-size: 17px;
        }

        @media all and (min-width: 768px) {
            font-size: 20px;
        }
    }
}

.label__placeholder {
    position: relative;

    margin-bottom: 10px;

    &--text {
        position: absolute;
        top: 50%;
        left: 0.67em;

        color: #858585;
        font-family: @title-font-family;
        font-weight: bold;
        font-size: 15px;

        transform: translate(0, -50%);
        cursor: text;
        transition: 0.3s;

        @media all and (min-width: 500px) {
            font-size: 17px;
        }

        @media all and (min-width: 768px) {
            font-size: 20px;
        }
    }

    &--input:focus,
    &--input.not-empty {
        + .label__placeholder--text {
            top: 2px;
            left: 0.27em;

            font-size: 6px;

            transform: none;

            @media all and (min-width: 500px) {
                font-size: 8px;
            }

            @media all and (min-width: 768px) {
                font-size: 10px;
            }
        }
    }

    &--input {
        margin-bottom: 0;

        @media all and (min-width: 500px) {
            padding: 12px;
        }

        @media all and (min-width: 768px) {
            padding: 15px;
        }

        &:required {
            background-image: url('@{images-folder}required.png');
            background-repeat: no-repeat;
            background-position: right 3px top 3px;
        }

        &::placeholder {
            opacity: 0;
        }
    }
}

.info,
.required__text {
    color: @body-text-color;
    font-family: @title-font-family;
    font-weight: bold;
    font-size: 9px;

    @media all and (min-width: 500px) {
        font-size: 11px;
    }

    @media all and (min-width: 768px) {
        font-size: 12px;
    }
}

.required__text {
    display: flex;
    align-items: center;

    &::before {
        display: block;
        width: 7px;
        height: 6px;
        margin-right: 3px;

        background-image: url('@{images-folder}required.png');
        background-repeat: no-repeat;
        background-size: contain;

        content: '';
    }
}

.jq-selectbox.s-payment {
    .z-index(20) !important;

    &:nth-of-type(2) {
        .z-index(15) !important;
    }

    &:nth-of-type(3) {
        .z-index(10) !important;
    }
}

.long-h1 {
    margin-bottom: 0;

    font-size: 16px;
    font-family: @title-font-family;
    color: @base-color;
    text-align: center;

    @media all and (max-width: 768px) {
        white-space: nowrap;
    }


    @media all and (min-width: 768px) {
        font-size: 18px;
    }

    &__wrapper {
        position: relative;

        padding: 8px;

        background-color: @primary-color;

        @media all and (max-width: 768px) {
            overflow-x: scroll;
        }

        &::before {
            position: absolute;
            top: 0;
            left: 0;

            width: 100%;
            height: 100%;

            /*background-image: linear-gradient(to right, transparent 0%, transparent 90%, @primary-color 100% );*/
            content: '';
        }
    }
}

.form .review_result__title {
    padding: 0 10px;
}

.review_result__title .f-attention {
    text-shadow: 1px 1px 0px rgba(255, 0, 0, 0.12), 1px 2px 0px rgba(197, 7, 7, 0.42);
}

.cat_names{
    width: 80%;
    word-break: normal !important;
}
.collaps-item__name{
    h2{
        font-size: 14px;
        margin-bottom: 0;
    }
}

.sticky {
    position: sticky;
    top: 55px;
    .z-index(11);
}

.s-payment-delivery .jq-selectbox__dropdown ul {
    max-height: 300px;
    overflow-y: scroll;
}

.top-block {
    #search {
        padding-top: 50px;

        .form-search {
            padding-top: 0;
        }
    }
}

#ya-site-form0 .ya-site-form__submit_type_image {
    .sizes(24px, 19px);
    margin-left: 0;

    background-image: url('@{icons-folder}search.svg') !important;
}

#ya-site-form0 .ya-site-form__wrap {
    width: auto !important;
}

.b-head__l, .b-head__r {
    vertical-align: middle;
    padding: 15px;
}

body .g-gap-horizontal {
  margin-left: 0 !important;
}

.b-pager__arrow {
  @media all and (max-width: 359px) {
    font-size: 11px;
  }

  @media all and (min-width: 360px) and (max-width: 440px) {
    font-size: 12px;
  }
}

.b-pager__title {
  @media all and (max-width: 800px) {
    font-size: 14px;
  }

  @media all and (min-width: 360px) and (max-width: 440px) {
    font-size: 15px;
  }
}

.j-collaps-click {
    cursor: pointer;
}

.series {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;

    text-align: center;

    &__item {
        margin-bottom: 10px;

        font-family: @special-font-family;

        @media all and (max-width: 767px) {
            width: 49%;
        }

        @media all and (min-width: 768px) {
            width: 24%;
        }
    }

    &.series--justify {
        justify-content: space-between;

        text-align: left;

        @media all and (min-width: 500px) {
            margin-left: 25px;
        }
    }
}

@supports (display: grid) {
    .nav .producers .ul-lvl-2 {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(140px, 1fr));
        grid-auto-flow: row;

        .li-lvl-2 {
            width: auto;
            min-width: 10px;
        }
    }

    .series {
        display: grid;
        grid-auto-flow: row;
        grid-gap: 7px 10px;

        @media all and (max-width: 399px) {
            grid-template-columns: repeat(1, 1fr);
        }

        @media all and (min-width: 400px) and (max-width: 599px){
            grid-template-columns: repeat(2, 1fr);
        }

        @media all and (min-width: 600px){
            grid-template-columns: repeat(4, 1fr);
        }

        &__item {
            width: 100%;
        }
    }

    .series-three {
        @media all and (min-width: 600px){
            grid-template-columns: repeat(3, 1fr);
        }
    }
}

/*.bottom-menu__body {
    justify-content: flex-start;
}

.bottom-menu__link {
    width: ~'calc((100% - 85px) / 4)';

    &--share {
        width: 85px;

        &::before {
            display: none;
        }
    }
}*/
@media (min-width: 768px) {
    .cart__img {
        max-width: 120px;
        margin-right: 8px;
    }

    .cart__text--wrapper {
        flex-wrap: wrap;
        padding-right: 11em;
    }

    .cart__producer,
    .cart__name {
        margin-right: 1em;
        margin-left: 0;
    }

    .cart__name {
        margin-left: 0;
    }
}

.another-producers {
    display: grid;
    grid-template-columns: 1fr;
    max-width: 800px;
    margin: 0 auto;

    text-align: center;

    @media (min-width: 768px) {
        grid-template-columns: 1fr 1fr;

        text-align: left;
    }

    &__link {
        position: relative;

        font-weight: bold;
        font-size: 16px;
        text-decoration: underline;

        &:hover {
            text-decoration: none;
        }

        &::after {
            content: '';
            position: relative;
            top: 0;
            left: 2px;

            display: inline-block;
            width: 10px;
            height: 10px;

            border-top: 2px solid @primary-color;
            border-right: 2px solid @primary-color;

            transform: rotate(45deg);
        }
    }
}


.grid {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;

    &-half > .grid__item {
        @media all and (min-width: 600px) {
            width: 49%;
        }
    }

    &-third > .grid__item {
        @media all and (min-width: 600px) and (max-width: 1023px){
            width: 49%;
        }

        @media all and (min-width: 1024px) {
            width: 32%;
        }
    }

    &-quater > .grid__item {
        @media all and (min-width: 1024px) {
            width: 19%;
        }
    }

    &-fifth > .grid__item {
        @media all and (max-width: 1023px){
            width: 49%;
        }

        @media all and (min-width: 1024px) {
            width: 19%;
        }
    }
}

@supports (display: grid) {
    .grid {
        display: grid;
        grid-auto-flow: row;
        grid-gap: 0.8em;

        @media all and (max-width: 599px) {
            justify-content: center;
        }

        &-half {
            @media all and (min-width: 600px) {
                grid-template-columns: repeat(2, 1fr);
            }
        }

        &-third {
            @media all and (min-width: 600px) and (max-width: 1023px) {
                grid-template-columns: repeat(2, 1fr);
            }

            @media all and (min-width: 1024px) {
                grid-template-columns: repeat(3, 1fr);
            }
        }

        &-third-auto {
            @media all and (min-width: 1024px) {
                grid-template-columns: repeat(3, auto);
                grid-gap: 0.8em 1.5em;
            }
        }

        &-quater {
            @media all and (min-width: 600px) and (max-width: 1023px) {
                grid-template-columns: repeat(2, 1fr);
            }

            @media all and (min-width: 1024px) {
                grid-template-columns: repeat(4, 1fr);
            }
        }

        &-fifth {
            @media all and (max-width: 1023px) {
            grid-template-columns: repeat(2, 1fr);
        }

            @media all and (min-width: 1024px) {
                grid-template-columns: repeat(5, 1fr);
            }
        }

        .grid__item {
            width: 100%;
        }
    }
}

.submenu_series {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  width: 100%;
  margin: 5px 0;

  padding: 3px 3px 3px 0;

  list-style: none;

  @media (min-width: 768px) {
    width: 200%;
  }

  li {
    position: relative;
    width: 100%;
    padding-left: 10px;

    @media (min-width: 768px) {
      width: 49%;
    }

    &:before {
      content: "";
      position: absolute;
      left: 0;
      top: 5px;

      width: 6px;
      height: 6px;

      background: url(/images/arrow_submenu.svg) 0 0;

    }

    a {
      font-size: 12px;
      color: white;

      @media (max-width: 768px) {
        padding-left: 0;
      }
    }
  }
}

.ul-lvl-2 .li-lvl-2:nth-child(2n) .submenu_series {
  @media (min-width: 768px) {
    transform: translate(-50%, 0);
  }
}

.table_blue_orange {
  width: 100%;
  overflow-x: auto;

  @media (min-width: 768px) {
    width: 80%;
    margin: 0 auto 20px;
  }

  th, td {
    font-size: 16px;
    line-height: 18px;
  }

  th, .table_blue_orange_th {
    padding: 15px 10px;
    font-weight: bold;
    color: #FFFFFF;

    background: @base-revers-color !important;
    text-align: left;
    vertical-align: middle;

    &:first-child {
      text-align: center;
    }
  }

  td {
    padding: 7px;

    color: #000000;

    text-align: center;
    border: 0.5px solid rgba(0, 16, 52, 0.2);

    &:first-child {
      font-weight: 700;
      color: #ffffff;
      background: #FFA62B;
    }
  }
}

.overflow_inherit {
  overflow: inherit;
}

.line__bottom {
  margin: 38px auto 28px;
  width: 80%;
  height: 2px;

  background: #717171;
}

.flex__bett {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.vid_dymohod {
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 25%;
  text-align: center;

  img {
    width: 126px;
  }

  a {
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    text-decoration-line: underline;
    color: @primary-color;
  }
}

.vid_dymohod__text {
  width: 75%;

  p {
    margin-bottom: 24px;
  }
}

.flex__two {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.w_desc_50 {
  width: 49%;

  @media (max-width: 600px) {
    width: 100%;
  }
}

.flex_al_start {
  align-items: flex-start;
}

.flex_al_center {
  align-items: center;
}

.fl__just_around {
  justify-content: space-around;
}

.fl__just_center {
  justify-content: center;
}


.block__links {
  margin-bottom: 16px;
  padding: 17px 12px 17px 42px;

  background-color: #F8FAFF;
  border: 1px solid black;
}

.icon__fire {
  position: relative;
  margin-bottom: 14px;

  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
  color: #000000;

  text-transform: uppercase;

  &:before {
    content:url("/images/icon_fire.svg");
    position: absolute;
    left: -32px;
    top: -5px;
  }
}

.ul-links {
  list-style: none;

  li {
    position: relative;
    padding-left: 18px;
    margin: 4px 0;

    &:before {
      content:url("/images/right-arrow-link.svg");
      position: absolute;
      left: 0;
      top: 3px;
    }

    a {
      font-size: 14px;
      line-height: 18px;
      color: #000000;

      &:hover {
        font-weight: 700;
        color: @warning-color;
        text-decoration-line: underline;
      }
    }
  }
}

.ul-links-2 {
    display: flex;
    flex-wrap: wrap;

    justify-content: space-between;

    li {
        width: 100%;
        @media (min-width: 800px) {
            width: 49%;
        }
    }

    .ul-links-2-w30 {
        @media (min-width: 800px) {
          width: 40%;
        }
    }

    .ul-links-2-w70 {
        @media (min-width: 800px) {
          width: 60%;
        }

    }

}

.width-h2 {
  width: 100%;
  margin-top: 50px;
}

.buy-h2 {
  text-transform: uppercase;
  padding-top: 16px;
}

.f-yellow {
  color: #fd970c;
}

.buy_block {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 10px;
  padding-top: 20px;
}

.buy_round {
  background: #001034;
  width: 65px;
  height: 65px;
  border-radius: 100%;
  img {
    display: block;
    margin: 10px auto;
  }
}

.descr {
  width: 80%;
}

.payment {
    width: 80%;
    display: flex;
    justify-content:space-around;
    flex-wrap: wrap;
    margin: 27px auto;
}


.line__payment {
    width: 95%;
    height: 0.5px;
    border: 0.5px solid rgba(0, 0, 0, 0.2);
    margin: 0 auto 10px;
}

.line__payment_2 {
    width: 40%;
    height: 0.5px;
    border: 0.5px solid rgba(0, 0, 0, 0.2);
    margin: 10px auto;
}

.payment-block p {
    text-align: center;
    margin-top: 13px;
}

.payment-block img {
    display: block;
    margin: 0 auto;
}

.link-dc {
    text-decoration: underline;
}

.p-choice {
    text-align: center;
    font-size: 21px;
    margin-top: 24px;
}

.input-search {
    display: inline-block;
    width: 80%;
    height: 43px;
    padding-left: 20px;
    border: 1px solid #b3b3b3;
    border-radius: 0px;
    font-size: 15px;
}

.form-choice {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 55%;
    margin: 0 auto;
}

.links_city .page-nav {
    list-style: none;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    width: 60%;
    margin: 0 auto 30px;
    text-decoration: underline;
    @media all and (max-width: 700px) {
      width: 100%;
    }
}

.links_city .page-nav__item a {
    color: #418FD7;
    font-size: 14px;
}

.links_city .page-nav__item {
    padding-left: 36px;
    padding-bottom: 16px;
    @media all and (max-width: 700px) {
      padding-left: 16px;
    }
}

.links-4 {
    display: flex;
    justify-content: space-between;
}

li.ul-links-2-w55 {
    width: 55%;
}

li.ul-links-2-w45 {
    width: 45%;
}

.delivery-result-main {
    position: absolute;
    top: 37px;
    left: 30%;
    .z-index(10);

    padding: 10px 15px;
    box-sizing: border-box;

    font-size: 12px;

    background-color: rgba(255,255,255,0.7);

    @media screen and (min-width: 600px) {
        margin-top: 40.5px;
        left: 23%;

        font-size: 15px;
    }

    @media screen and (min-width: 768px) {
        left: 25%;
        margin-top: 33px;
    }
}

.links_city {
    margin-top: 70px;
}

.delivery__search::after {
    content: '';
    position: absolute;
    bottom: -40px;
    right: 10%;

    display: block;
    .sizes(60px, 90px);

    background-image: url('@{images-folder}search-eye.jpg');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;

    @media all and (min-width: 360px) {
      .sizes(75px, 110px);
    }
}

.input-search {
    display: inline-block;
    width: 80%;
    height: 43px;
    margin-top: 30px;
    padding-left: 20px;
    border: 1px solid #b3b3b3;
    border-radius: 0px;
    font-size: 15px;
}

@media (max-width: 600px) {
    .ul_in-imgs {
        flex-wrap: wrap;

        .ul {
            order: 3;
            width: 100%;
        }

        img:last-child {
            order:2;
        }

        img {
            width: 50%;
        }
    }
}

.light-table {

    font-size: 16px;
    line-height: 18px;

    thead th {
        padding: 15 3px;

        color: @font-revers-color;
        font-weight: bold;
        text-align: center;
        vertical-align: middle;

        background-color: @primary-color;
    }

    td {
        border: 0.5px solid rgba(0, 16, 52, 0.2);
    }

    td:first-child {
        background-color: @table-first-col;
        border-right: none !important;

        a {
            border-bottom: 2px solid @font-revers-color;

            text-decoration: none;
            color: @font-revers-color;
            font-weight: bold;
            font-size: 16px;
            line-height: 18px;
        }

        a:hover {
            border-bottom: none;
        }
    }

    td:not(:first-child) {
        text-align: center;
        vertical-align: middle;
    }
}

@media (max-width: 590px) {

    .light-table {
        font-size: 14px;
        line-height: 16px;

        thead th {
            padding: 10 0px;
        }

        td:first-child a {
            font-size: 14px;
            line-height: 16px;
        }
    }

}

@media (max-width: 490px) {

    .light-table_box {
        overflow-x: auto;
    }

    .light-table {
        font-size: 12px;
        line-height: 14px;

        thead th {
            padding: 5 0px;
        }

        td:first-child a {
            font-size: 12px;
            line-height: 14px;
        }
    }

}

@media (max-width: 340px) {

    .light-table thead th {
        font-size: 10px;
    }

}

.ul_sandwich-advantages {

    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    li {
        width: 49%;
    }

}

@media (max-width: 650px) {

    .ul_sandwich-advantages {

        li {
            width: 100%;
        }

    }

}

.collaps-item__filter {
  display: none;
}

@media (max-width: 1099px) {
  .container__filter_mob {
    padding-top: 5px;
    flex-wrap: wrap;
    gap: 10px;

    .collaps-item__name {
      display: inline-flex;
    }

    .flex__row-full.filter {
      font-size: 10px;
    }

    .icons-svg.icons--filter {
      width: 18px;
      height: 25px;
    }

    .flex__row-full.sort {
      font-size: 9.8px;
    }

  }
}

@media (max-width: 400px) {
  .container__filter_mob {
    .jq-selectbox__dropdown li, .jq-selectbox__select-text {
      width: 120px!important;
    }
  }
}

.ul-column-2 {
    column-count: 2;
    li {
        margin: 0 15px;
        @media(max-width: 480px) {
            margin: 0;
        }
    }
    @media(max-width: 480px) {
        column-count: 1;
    }
}

@media (max-width: 400px) {
    .search-data__title {
      font-size: 12.64px;
    }
  }
  
  .goods-table .good-chars__elem, .goods-table .good-chars__item {
    font-weight: 400;
    font-size: 12.8px;
    font-family: Tahoma,sans-serif;
  }
  
  @media (max-width: 400px) {
    .good-media-footer .f-uppercase.f-underline.f-light-base, .goods-table .f-uppercase.f-underline.f-light-base {
      font-size: 12.5px;
    }
  }
  
  @media screen and (max-width: 768px) {
    .elem-item__basket button {
      padding: 4px 6px;
      font-size: 12.8px;
    }
  }
  
  @media (min-width: 400px) {
    .subscribe-form__footer {
      font-size: 12px;
    }
  }
  @media (max-width: 400px) {
    .subscribe-form__footer {
      font-size: 12px;
    }
  }
  
  @media (max-width: 1099px) {
    .container__filter_mob .flex__row-full.sort {
      font-size: 12.8px;
    }
  }
  
  @media (max-width: 400px) {
    .good-media-footer .good-media-footer__item--compare .btn-active, .goods-table .good-media-footer__item--compare .btn-active {
      padding: 4px 5px;
      font-size: 12.8px;
    }
  }
  
  .good-chars__item {
    font-size: 12px;
  }
  
  @media screen and (max-width: 999px) {
    .experience__item .radio-btn-group__label {
      font-size: 12px;
      line-height: 13px;
    }
  }
  
  @media (max-width: 1099px) {
    .container__filter_mob .flex__row-full.filter {
      font-size: 12px;
    }
  }

  @media screen and (max-width: 400px) {
    .description-subcat p {
        font-size: 12px;
    }
  }

  .subscribe-form__submit {
    font-size: 12.2px;
}